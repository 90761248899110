import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "./master.scss";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import LoginPage from "./components/pages/LoginPage";
import { Component } from "react";
import { EventBus } from "./utilities/EventBus";
import LoadingScreen from "./components/widgets/LoadingScreen";
import DialogBox from "./components/widgets/DialogBox";
import { DialogPack } from "./configs/DialogConstants";
import EditQuestionPage from "./components/pages/EditPaperQuestionPage";
import packageJson from "../package.json";
import StatisticsPage from "./components/pages/StatisticsPage";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      loadingMessage: "",
      dialogPack: DialogPack,
    };
    this.eventBusInitialized = false;
  }

  componentDidMount() {
    this.initiateEventBus();
  }

  initiateEventBus() {
    if (!this.eventBusInitialized) {
      EventBus.on("loading", data => {
        this.setState({
          showLoading: data.showLoading,
          loadingMessage: data.loadingMessage,
        });
      });
      EventBus.on("dialog", data => {
        this.setState({
          dialogPack: data.dialogPack,
        });
      });
      this.eventBusInitialized = true;
    }
  }

  componentWillUnmount() {
    EventBus.remove("loading");
    EventBus.remove("dialog");
  }

  render() {
    return (
      <div className="force-width">
        <div>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LoginPage />}></Route>
              <Route path="/login" element={<LoginPage />}></Route>
              <Route
                path="/edit-paper-question/:id/:m?"
                element={<EditQuestionPage />}></Route>
              <Route
                path="/edit-paper-question"
                element={<EditQuestionPage />}></Route>
              <Route path="/statistics" element={<StatisticsPage />}></Route>
              <Route
                path="/statistics/:id/:m?"
                element={<StatisticsPage />}></Route>
            </Routes>
          </BrowserRouter>
        </div>
        {this.state.showLoading && (
          <LoadingScreen label={this.state.loadingMessage} />
        )}
        {this.state.dialogPack.showDialog && (
          <DialogBox dialogPack={this.state.dialogPack} />
        )}
        <div className="version">v{packageJson.version}</div>
      </div>
    );
  }
}

export default App;
