import APIHeader from "../configs/APIHeader.js";
import APIPaths from "../configs/APIPaths";
import APIResult from "../configs/APIResult";
import { debugPrint } from "./Utilities";

function getProperBody(body, method) {
  console.log(method, "method");
  if (method === "GET" || method === "DELETE" || method === "OPTIONS") {
    return null;
  }
  if (method === "PUT") {
    return JSON.stringify(body);
  }
  if (method === "PUT-RAW") {
    return body;
  }
  return JSON.stringify(body);
}

async function callAPI(
  body,
  target,
  method = "POST",
  defaultReturn,
  contentType = APIHeader.contentType
) {
  // get token from local storage
  let token = "";
  let adminID = "";
  try {
    let data = JSON.parse(localStorage.getItem("jyreadingQuizAC"));
    adminID = data.sessionID;
    // insert as bearer token
    token = data.sessionToken;
  } catch (e) {
    debugPrint(e);
  }
  const requestOptions = {
    method: method.split("-")[0],
    headers: {
      "Content-Type": contentType,
      Authorization: `Bearer ${token}`,
      "admin-id": adminID,
    },
    body: getProperBody(body, method),
  };

  debugPrint(requestOptions);
  debugPrint(target);
  debugPrint(body);

  var proceed = true;

  Object.values(body).forEach(param => {
    if (param === null || param === undefined) {
      proceed = false;
    }
  });

  if (proceed) {
    await new Promise(resolve => setTimeout(resolve, 500));
    return await fetch(target, requestOptions)
      .then(response => {
        // if 401, redirect to login page
        if (response.status === 401) {
          // push to login page
          localStorage.removeItem("jyreadingQuizAC");
          window.location.href = "/login?e=1";
        }
        return response.json();
      })
      .then(responseData => {
        debugPrint(responseData);
        try {
          if (responseData.status === APIResult.INVALID_SESSION) {
            // push to login page
            localStorage.removeItem("jyreadingQuizAC");
            window.location.href = "/login?e=1";
          }
        } catch (e) {
          debugPrint(e);
        }
        return responseData;
      })
      .catch(error => {
        console.warn(error);
        return defaultReturn;
      });
  } else {
    return defaultReturn;
  }
}

export async function adminSignIn(body) {
  var target = APIPaths.apiEndPoint.concat(APIPaths.adminSignIn);

  return callAPI(body, target, "POST", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function adminUploadImage(img, fileType) {
  var target = APIPaths.apiEndPoint.concat(APIPaths.adminUploadImage);

  return callAPI(
    img,
    target,
    "PUT-RAW",
    {
      status: APIResult.TIMED_OUT,
      status_message: "-",
    },
    fileType
  );
}

export async function adminCreatePaper(body) {
  var target = APIPaths.apiEndPoint.concat(APIPaths.adminCreatePaper);

  return callAPI(body, target, "PUT", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function adminGetPaperList() {
  var target = APIPaths.apiEndPoint.concat(APIPaths.adminGetPaperList);

  return callAPI({}, target, "GET", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function adminReadPaper(id) {
  var target = APIPaths.apiEndPoint
    .concat(APIPaths.adminReadPaper)
    .concat(`/${id}`);

  return callAPI({}, target, "GET", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function adminUpdatePaper(paperID, body) {
  var target = APIPaths.apiEndPoint
    .concat(APIPaths.adminUpdatePaper)
    .concat(`/${paperID}`);

  return callAPI(body, target, "PATCH", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function adminDeletePaper(id) {
  var target = APIPaths.apiEndPoint
    .concat(APIPaths.adminDeletePaper)
    .concat(`/${id}`);

  return callAPI({}, target, "DELETE", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function adminUpdatePapersPublishStatus(questionIDs, status) {
  var target = APIPaths.apiEndPoint.concat(
    APIPaths.adminUpdatePapersPublishStatus
  );

  return callAPI(
    {
      ids: questionIDs,
      publish: status,
    },
    target,
    "PATCH",
    {
      status: APIResult.TIMED_OUT,
      status_message: "-",
    }
  );
}

export async function adminCreateQuestions(masterQuestions) {
  var target = APIPaths.apiEndPoint.concat(APIPaths.adminCreateQuestions);

  return callAPI({ "master-questions": masterQuestions }, target, "PUT", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function adminUpdateQuestion(questionID, masterQuestions) {
  var target = APIPaths.apiEndPoint
    .concat(APIPaths.adminUpdateQuestion)
    .concat(`/${questionID}`);

  return callAPI(masterQuestions, target, "PATCH", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function adminUpdateQuestionsPublishStatus(questionIDs, status) {
  var target = APIPaths.apiEndPoint.concat(
    APIPaths.adminUpdateQuestionsPublishStatus
  );

  return callAPI(
    {
      ids: questionIDs,
      publish: status,
    },
    target,
    "PATCH",
    {
      status: APIResult.TIMED_OUT,
      status_message: "-",
    }
  );
}

export async function adminSetPaperQuestionsSortation(paperID, sortation) {
  var target = APIPaths.apiEndPoint
    .concat(APIPaths.adminSetPaperQuestionsSortation)
    .concat(`/${paperID}`);

  return callAPI(
    {
      sortation: sortation,
    },
    target,
    "PATCH",
    {
      status: APIResult.TIMED_OUT,
      status_message: "-",
    }
  );
}

export async function clientGetPaperQuestionsSortation(paperID) {
  var target = APIPaths.apiEndPoint
    .concat(APIPaths.clientGetPaperQuestionsSortation)
    .concat(`/${paperID}`);

  return callAPI({}, target, "GET", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function adminDeleteQuestion(questionID) {
  var target = APIPaths.apiEndPoint
    .concat(APIPaths.adminDeleteQuestion)
    .concat(`/${questionID}`);

  return callAPI("", target, "DELETE", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function adminGetStatisticsData(body) {
  var target = APIPaths.apiEndPoint.concat(APIPaths.adminGetStatisticsData);

  return callAPI(body, target, "POST", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}
