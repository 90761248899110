import React from "react";
import "./PublishStatus.scss";
import TextButton from "./TextButton";
import {
  EventBus,
  HideDialog,
  HideLoading,
  ShowLoading,
} from "../../utilities/EventBus";
import { adminUpdateQuestionsPublishStatus } from "../../utilities/Requests";
import APIResult from "../../configs/APIResult";
import { DialogType, ShowMessage } from "../../configs/DialogConstants";

class PublishStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = { allChecked: false };

    this.allChecker = React.createRef();
  }

  getQuestion(question) {
    if (question.context !== "") {
      return question.context;
    }
    if (question.all_questions.length > 0) {
      return question.all_questions[0].header;
    }
  }

  isFull(question) {
    return question.context !== "";
  }

  selectUnselectAll(select) {
    var questionsState = { allChecked: select };
    for (var eachQuestion in this.props.paperSettings.questions) {
      questionsState[this.props.paperSettings.questions[eachQuestion]._id] =
        select;
    }
    this.setState(questionsState);
  }

  isAnythingChecked() {
    var isAnythingChecked = false;
    this.props.paperSettings.questions.every(eachQuestion => {
      if (this.state[eachQuestion._id]) {
        isAnythingChecked = true;
      }
      return !isAnythingChecked;
    });
    return isAnythingChecked;
  }

  async updatePublishStatus(status) {
    ShowLoading("更新發布狀態中...");
    var ids = [];
    for (let eachQuestion in Object.keys(this.state)) {
      let id = Object.keys(this.state)[eachQuestion];
      if (this.state[id]) {
        ids.push(id);
      }
    }
    let result = await adminUpdateQuestionsPublishStatus(ids, status);
    HideLoading();
    if (result.status === APIResult.SUCCESS) {
      ShowMessage(DialogType.SUCCESS, "已更新發布狀態", () => {
        HideDialog();
        EventBus.dispatch("edit-paper-question", {
          page: this.props.paperSettings._id,
        });
      });
    } else {
      ShowMessage(
        DialogType.ERROR,
        "更新發布狀態的流程中出現了一些不知名錯誤。\n\n" +
          JSON.stringify(result)
      );
    }

    var toUpdate = { allChecked: false };
    this.props.paperSettings.questions.forEach(eachQuestion => {
      toUpdate[eachQuestion._id] = false;
    });

    this.setState(toUpdate);
  }

  render() {
    return (
      <div className="publish-status-backdrop">
        <div className="flex-v ">
          <div
            key={this.state.alert}
            className={`p-m p-v-m h checkbox-wrapper-40 align-left`}
            style={{ gap: "1.5em" }}>
            <label className="">
              <input
                checked={this.state.allChecked}
                ref={this.allChecker}
                type="checkbox"
                onChange={e => {
                  this.selectUnselectAll(e.target.checked);
                }}
              />
            </label>
            {this.isAnythingChecked() && (
              <TextButton
                label="發布"
                callback={() => {
                  this.updatePublishStatus(true);
                }}
              />
            )}
            {this.isAnythingChecked() && (
              <TextButton
                label="取消發布"
                callback={() => {
                  this.updatePublishStatus(false);
                }}
              />
            )}
          </div>
          {this.props.paperSettings.questions.map((eachQuestion, i) => (
            <div
              key={`main-question-${i}`}
              className={`p-m p-v-s ${
                i !== this.props.paperSettings.questions.length - 1
                  ? "bottom-border"
                  : ""
              } ${this.state[eachQuestion._id] ? "highlighted" : ""}`}>
              <div
                key={this.state.alert}
                className={`w-100 checkbox-wrapper-40 publish-question-question-container`}>
                <label className="" style={{ paddingTop: "1.35em" }}>
                  <input
                    type="checkbox"
                    checked={this.state[eachQuestion._id] === true}
                    onChange={e => {
                      this.setState({ [eachQuestion._id]: e.target.checked });
                      this.allChecker.current.checked = false;
                    }}
                  />
                </label>
                <p className="publish-status-index">{i + 1}</p>
                <div className="fill-remaining flex gap-v-s no-overflow">
                  {this.isFull(eachQuestion) ? (
                    <p
                      className="publish-status-context"
                      dangerouslySetInnerHTML={{
                        __html: eachQuestion.context,
                      }}></p>
                  ) : null}
                  {eachQuestion.all_questions.map((eachSubQuestion, j) => (
                    <div
                      key={`sub-question-${i}-${j}`}
                      className="h"
                      style={{ gap: "1.5em" }}>
                      {this.isFull(eachQuestion) ? (
                        <p className="publish-status-subindex"> {j + 1}</p>
                      ) : null}
                      <p
                        className="publish-status-question"
                        dangerouslySetInnerHTML={{
                          __html: eachSubQuestion.header,
                        }}></p>
                    </div>
                  ))}
                </div>
                <p
                  className={`publish-status-publish-status ${eachQuestion.publish}`}>
                  {eachQuestion.publish ? "● 發布中" : "● 尚未發布"}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default PublishStatus;
