import React from "react";
import PropTypes from "prop-types";
import { debugPrint } from "../../utilities/Utilities";
import "./TextButton.scss";

class TextButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="text-button" onClick={this.props.callback}>
        <p>{this.props.label}</p>
      </div>
    );
  }
}

TextButton.propTypes = {
  label: PropTypes.string,
  callback: PropTypes.func,
};

TextButton.defaultProps = {
  label: "TextButton",
  callback: () => {
    debugPrint("TextButton pressed");
  },
};

export default TextButton;
