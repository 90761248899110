import React from "react";
import CreateNewPaperPage from "./CreateNewPaperPage";
import "./EditPaperQuestionPage.scss";
import ProfilePicture from "../../assets/images/profile_picture.png";
import withRouter from "../../utilities/withRouter";
import Sidebar from "../widgets/Sidebar";
import ModifyQuestionPage from "./ModifyQuestionPage";
import { EventBus } from "../../utilities/EventBus";
import { createBrowserHistory } from "@remix-run/router";

class EditQuestionPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPaper: "",
    };

    this.eventBusInitialized = false;
    this.modifyQuestionPage = React.createRef();
  }

  componentDidMount() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (this.props.params["id"]) {
      this.setState(
        {
          currentPaper: this.props.params["id"],
        },
        () => {
          if (this.modifyQuestionPage.current) {
            this.modifyQuestionPage.current.readPaper(this.props.params["id"]);
          }
        }
      );
    }
    if (params.m && this.modifyQuestionPage.current) {
      this.modifyQuestionPage.current.setMode(params.m);
    }
    this.initiateEventBus();
  }

  initiateEventBus() {
    if (!this.eventBusInitialized) {
      EventBus.on("edit-paper-question", data => {
        if (this.modifyQuestionPage.current) {
          this.modifyQuestionPage.current.resetQuestion();
        }
        this.setState({ currentPaper: data.page });
      });
      this.eventBusInitialized = true;
    }
  }

  componentWillUnmount() {
    EventBus.remove("edit-paper-question");
  }

  render() {
    return (
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className="h height-100vh">
        <div className="top-bar">
          <a className="main-title a-clear" href="https://www.jyreading.com">
            財團法人江雲教育基金會
          </a>

          <div className="top-bar-right">
            <div
              className="nav-title"
              onClick={() => {
                createBrowserHistory().push(`/statistics`);
                window.location.reload();
              }}>
              查看數據
            </div>

            <div
              className="nav-title"
              onClick={() => {
                createBrowserHistory().push(`/edit-paper-question/new`);
                window.location.reload();
              }}>
              編輯考題
            </div>

            <img
              alt="profile"
              src={ProfilePicture}
              className="profile-picture"
            />
          </div>
        </div>
        <div className="bottom-content">
          <Sidebar />
          <div className="main-content">
            <div
              className={`${this.state.currentPaper === "new" ? "" : "hide"}`}>
              <CreateNewPaperPage />
            </div>
            <div
              className={`${this.state.currentPaper !== "new" ? "" : "hide"}`}>
              <ModifyQuestionPage
                ref={this.modifyQuestionPage}
                currentPaper={this.state.currentPaper}
              />
            </div>
            {/* <Routes>
              <Route path=":id" element={<ModifyQuestionPage />} />
              <Route path="/new" element={<CreateNewPaperPage />} />
            </Routes> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditQuestionPage);
