import React from "react";
import PropTypes from "prop-types";
import "./MasterQuestion.scss";
import Question from "./Question";
import {
  makeID,
  debugPrint,
  checkIfStringIsHTML,
} from "../../utilities/Utilities";
import { HideDialog, ShowDialog } from "../../utilities/EventBus";
import GenericIconButton from "./GenericIconButton";
import DeletePaperIcon from "../../assets/images/delete_paper_icon.svg";
import { DialogType, GetDialogPack } from "../../configs/DialogConstants";
import { FormControl, InputLabel, Select, TextField } from "@mui/material";
import DragAndDropUploadImage from "./DragAndDropUploadImage";
import { PaperStructure } from "../../configs/PaperConstants";
import FlatButton from "./FlatButton";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import BoldIcon from "../../assets/images/bold_icon.png";
import UnderlineIcon from "../../assets/images/underline_icon.png";

class MasterQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      context: "",
      quote: "",
      image: "",
      newQuestions: [],
      publish: false,
      category: "",
      mockType: "",
      mockCategory: "",
      contextEditorState: EditorState.createWithContent(
        ContentState.createFromText("")
      ),
    };

    this.allNewQuestions = {};
    this.textFieldStyle = "filled";

    this.addQuestion = this.addQuestion.bind(this);
    this.setMasterQuestionObject = this.setMasterQuestionObject.bind(this);
    this.getMasterQuestionObject = this.getMasterQuestionObject.bind(this);
    this.getAllQuestionObjects = this.getAllQuestionObjects.bind(this);
    this.setContextEditor = this.setContextEditor.bind(this);
    this.onContextChanged = this.onContextChanged.bind(this);

    this.contextImage = React.createRef();
    this.contextEditor = null;
  }

  async addQuestion() {
    var newQuestions = this.state.newQuestions;
    let id = `${Date.now()}-${makeID(8)}`;
    newQuestions.push(id);
    await this.setState({ newQuestions: newQuestions });
    return id;
  }

  async autoAddQuestion() {
    for (var eachQuestion in this.props.masterQuestion.all_questions) {
      let id = await this.addQuestion();
      this.allNewQuestions[id].setQuestionObject(
        this.props.masterQuestion.all_questions[eachQuestion]
      );
    }
  }

  getEditorState(value) {
    if (checkIfStringIsHTML(value)) {
      let fromHTML = htmlToDraft(value);
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(
          fromHTML.contentBlocks,
          fromHTML.entityMap
        )
      );
    } else {
      return EditorState.createWithContent(ContentState.createFromText(value));
    }
  }

  getTitle() {
    if (this.isArticle()) {
      return `題組${this.props.index + 1}`;
    }
    return `題目${this.props.index + 1}`;
  }

  saveValue(key, value) {
    this.setState({ [key]: value });
  }

  componentDidMount() {
    if (this.props.masterQuestion !== null) {
      if (this.isArticle()) {
        this.contextImage.current.setImage(this.props.masterQuestion.image);
      }
      let processedMasterQuestion = this.props.masterQuestion;
      let contextEditorState = this.getEditorState(
        processedMasterQuestion["context"]
      );
      processedMasterQuestion["contextEditorState"] = contextEditorState;
      processedMasterQuestion["mockType"] =
        processedMasterQuestion["mock_type"] ?? "";
      processedMasterQuestion["mockCategory"] =
        processedMasterQuestion["mock_category"] ?? "";

      this.setState(this.props.masterQuestion, this.autoAddQuestion);
    } else {
      this.addQuestion();
    }
  }

  isArticle() {
    return (
      this.props.paperSettings.type === "題組" || this.state.mockType === "題組"
    );
  }

  isMock() {
    return this.props.paperSettings.type === "模擬考";
  }

  getAllQuestionObjects() {
    var allQuestionObject = [];
    Object.keys(this.allNewQuestions).forEach(eachQuestion => {
      if (this.allNewQuestions[eachQuestion]) {
        allQuestionObject.push(
          this.allNewQuestions[eachQuestion].getQuestionObject(
            this.state.mockType === "題組"
          )
        );
      }
    });

    return allQuestionObject;
  }

  setMasterQuestionObject() {}

  getMasterQuestionObject() {
    let masterQuestionObject = {
      paper_id: this.props.paperSettings._id,
      type: this.props.paperSettings.type,
      category:
        this.state.category !== ""
          ? this.state.category
          : this.props.paperSettings.category,
      is_mock: this.props.isMock,
      mock_type: this.state.mockType,
      mock_category: this.state.mockCategory,
      context: this.state.context,
      image: this.state.image,
      all_questions: this.getAllQuestionObjects(),
      publish: this.state.publish,
      quote: this.state.quote,
    };

    return masterQuestionObject;
  }

  onContextChanged(editorState) {
    // let type = editorState.getCurrentContent().getBlocksAsArray()[0].getType();
    // get html from editorState based on type
    // get editorState type

    this.setState({
      contextEditorState: editorState,
      context: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  }

  setContextEditor(editor) {
    this.contextEditor = editor;
  }

  render() {
    return (
      <div className="master-question-container">
        {/* {this.props.paperSettings.type === "模擬考" && (
          <div className="master-question-category-options">
            {Object.keys(PaperStructure).map(eachType => {
              if (eachType === "模擬考") {
                return null;
              }
              return (
                <FlatButton
                  key={eachType}
                  label={eachType}
                  mini={true}
                  fit={true}
                  curve={true}
                  callback={() => {
                    this.setState({ mockType: eachType });
                  }}
                  active={this.state.mockType === eachType ? true : false}
                />
              );
            })}
          </div>
        )} */}
        {/* {this.state.mockType !== "" && (
          <div className="master-question-category-options">
            {PaperStructure[this.state.mockType].map(eachCategory => {
              if (eachCategory === "檢測考題") {
                return null;
              }
              return (
                <FlatButton
                  key={eachCategory}
                  label={eachCategory}
                  mini={true}
                  fit={true}
                  curve={true}
                  callback={() => {
                    this.setState({ mockCategory: eachCategory });
                  }}
                  active={
                    this.state.mockCategory === eachCategory ? true : false
                  }
                />
              );
            })}
          </div>
        )} */}
        {!this.props.editMode && (
          <div className="master-question-title-bar rel">
            <div className="master-question-title">{this.getTitle()}</div>
            <div className="abs-end">
              <GenericIconButton
                icon={DeletePaperIcon}
                size={16}
                callback={() => {
                  ShowDialog(
                    GetDialogPack({
                      showDialog: true,
                      dialogType: DialogType.DELETE,
                      dialogTitle: "刪除",
                      dialogMessage: "是否確定刪除此考題？",
                      dialogShowPrimaryButton: true,
                      dialogPrimaryButtonLabel: "確認",
                      dialogPrimaryButtonCallback: this.props.deleteQuestion,
                      dialogShowSecondaryButton: true,
                      dialogSecondaryButtonLabel: "返回",
                      dialogSecondaryButtonCallback: HideDialog,
                      dialogShowCheckBox: true,
                      dialogCheckBoxMessage: "是的，我要刪除",
                    })
                  );
                }}
              />
            </div>
          </div>
        )}
        {this.props.paperSettings.type === "模擬考" && (
          <div
            className={`${
              this.props.editMode ? "" : "abs-start"
            } master-question-category-options`}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel htmlFor="grouped-native-select">考題類型</InputLabel>
              <Select
                native
                sx={{
                  color: "#1781BC",
                  "& .MuiSvgIcon-root": {
                    color: "#1781BC",
                  },
                }}
                value={JSON.stringify({
                  type: this.state.mockType,
                  category: this.state.mockCategory,
                })}
                id="grouped-native-select"
                onChange={e => {
                  let value = JSON.parse(e.target.value);
                  this.setState({
                    mockType: value.type,
                    mockCategory: value.category,
                  });
                }}
                label="考題類型">
                <option aria-label="None" value="" />
                {Object.keys(PaperStructure).map((eachType, i) => {
                  if (eachType === "模擬考") {
                    return null;
                  }
                  return (
                    <optgroup label={eachType} key={`typeGrp-${eachType}-${i}`}>
                      {PaperStructure[eachType].map((eachCategory, j) => {
                        if (eachCategory === "檢測考題") {
                          return null;
                        }
                        return (
                          <option
                            key={`category-${eachCategory}-${j}`}
                            value={JSON.stringify({
                              type: eachType,
                              category: eachCategory,
                            })}>
                            {eachCategory}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
                {/* <optgroup label="Category 2">
                  <option value={3}>Option 3</option>
                  <option value={4}>Option 4</option>
                </optgroup> */}
              </Select>
            </FormControl>
          </div>
        )}
        {this.isArticle() && (
          <>
            <div className="master-question-container">
              <Editor
                toolbar={{
                  options: ["inline"],
                  inline: {
                    options: ["bold", "underline"],
                    bold: { icon: BoldIcon, className: "drafjs-custom-icon" },
                    underline: {
                      icon: UnderlineIcon,
                      className: "drafjs-custom-icon",
                    },
                  },
                }}
                placeholder="引文"
                ref={this.setContextEditor}
                editorState={this.state.contextEditorState}
                wrapperClassName="editor-wrapper"
                editorClassName="text-field"
                onEditorStateChange={this.onContextChanged}
              />
              {/* <TextField
                value={this.state.context}
                variant={this.textFieldStyle}
                multiline
                fullWidth
                label="引文"
                onChange={e => this.saveValue("context", e.target.value)}
              /> */}
              <TextField
                value={this.state.quote}
                variant={this.textFieldStyle}
                multiline
                fullWidth
                label="出處引述"
                onChange={e => this.saveValue("quote", e.target.value)}
              />
              <DragAndDropUploadImage
                ref={this.contextImage}
                callback={image => {
                  this.setState({ image: image });
                }}
              />
            </div>
          </>
        )}
        {this.state.newQuestions.map((eachNewQuestion, i) => (
          <div
            key={eachNewQuestion}
            className={`question-block gap-v-m ${
              this.props.paperSettings.type === "題組" ? "grey-backdrop" : ""
            }`}>
            <Question
              textFieldStyle={this.textFieldStyle}
              paperSettings={this.props.paperSettings}
              mockType={this.state.mockType}
              index={i}
              deleteQuestion={() => {
                delete this.allNewQuestions[eachNewQuestion];
                this.setState({
                  newQuestions: Object.keys(this.allNewQuestions),
                });
                HideDialog();
              }}
              ref={r => {
                this.allNewQuestions[eachNewQuestion] = r;
              }}
              _id={eachNewQuestion}
            />
          </div>
        ))}
        {this.props.paperSettings.category === "檢測考題" && (
          <div className="master-question-category-options">
            {Object.keys(PaperStructure).includes(
              this.props.paperSettings.type
            ) &&
              PaperStructure[this.props.paperSettings.type].map(eachAbility => {
                if (eachAbility === "檢測考題") {
                  return null;
                }
                return (
                  <FlatButton
                    key={eachAbility}
                    label={eachAbility}
                    mini={true}
                    fit={true}
                    curve={true}
                    callback={() => {
                      this.setState({ category: eachAbility });
                    }}
                    active={this.state.category === eachAbility ? true : false}
                  />
                );
              })}
          </div>
        )}
        <div className="master-question-publish-group">
          <div className="master-question-publish-text">發布</div>
          <input
            role="switch"
            type="checkbox"
            checked={this.state.publish}
            onChange={v => {
              this.setState({ publish: v.target.checked });
            }}
          />
        </div>
        {this.isArticle() && (
          <div
            className="master-question-add-question-button unselectable"
            onClick={this.addQuestion}>
            ＋新增問答
          </div>
        )}
      </div>
    );
  }
}

MasterQuestion.propTypes = {
  callback: PropTypes.func,
  masterQuestion: PropTypes.object,
  editMode: PropTypes.bool,
};

MasterQuestion.defaultProps = {
  callback: () => {
    debugPrint("MasterQuestion pressed");
  },
  masterQuestion: null,
  editMode: false,
};

export default MasterQuestion;
