import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import BoldIcon from "../../assets/images/bold_icon.png";
import UnderlineIcon from "../../assets/images/underline_icon.png";
import "./Question.scss";
import htmlToDraft from "html-to-draftjs";
import { AnswerTags, PaperStructure } from "../../configs/PaperConstants";
import DragAndDropUploadImage from "./DragAndDropUploadImage";
import GenericIconButton from "./GenericIconButton";
import DeletePaperIcon from "../../assets/images/delete_paper_icon.svg";
import { HideDialog, ShowDialog } from "../../utilities/EventBus";
import { DialogType, GetDialogPack } from "../../configs/DialogConstants";
import FlatButton from "./FlatButton";
// import FlatButton from "./FlatButton";

class Question extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: null,
      category: "",
      answers: AnswerTags.reduce((a, v) => ({ ...a, [v]: "" }), {}),
      correctAnswer: 0,
      header: "",
      description: "",
      explanation: "",
      image: "",
      source: "",
      headerEditorState: null,
      descriptionEditorState: null,
      mockType: "",
      mockCategory: "",
      sortation: "題圖引",
    };

    this.saveValue = this.saveValue.bind(this);
    this.saveAnswer = this.saveAnswer.bind(this);
    this.setCorrectAnswer = this.setCorrectAnswer.bind(this);
    this.onHeaderChanged = this.onHeaderChanged.bind(this);
    this.onDescriptionChanged = this.onDescriptionChanged.bind(this);
    this.setHeaderEditor = this.setHeaderEditor.bind(this);
    this.setDescriptionEditor = this.setDescriptionEditor.bind(this);
    this.focusQuestion = this.focusQuestion.bind(this);
    this.getQuestionObject = this.getQuestionObject.bind(this);
    this.setQuestionObject = this.setQuestionObject.bind(this);

    this.questionImage = React.createRef();

    this.headerEditor = null;
    this.descriptionEditor = null;
  }

  componentDidMount() {
    this.focusQuestion();
  }

  getEditorState(value) {
    let fromHTML = htmlToDraft(value);
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(
        fromHTML.contentBlocks,
        fromHTML.entityMap
      )
    );
  }

  focusQuestion() {}

  onHeaderChanged(editorState) {
    this.setState({
      headerEditorState: editorState,
      header: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  }

  onDescriptionChanged(editorState) {
    this.setState({
      descriptionEditorState: editorState,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  }

  setHeaderEditor(editor) {
    this.headerEditor = editor;
  }

  setDescriptionEditor(editor) {
    this.descriptionEditor = editor;
  }

  saveValue(key, value) {
    this.setState({ [key]: value });
  }

  saveAnswer(key, value) {
    var answers = this.state.answers;
    answers[key] = value;
    this.setState({ answers: answers });
  }

  setCorrectAnswer(e) {
    this.setState({
      correctAnswer: e.target.value,
    });
  }

  getQuestionObject(isArticle) {
    var masterQuestion = {
      _id: this.state._id,
      header: this.state.header,
      description: this.state.description,
      image: this.state.image,
      answers: this.state.answers,
      correct_answer: this.state.correctAnswer,
      explanation: this.state.explanation,
      source: this.state.source,
      category: this.state.category,
      sortation: this.state.sortation,
      mock_type:
        isArticle || this.props.paperSettings.type === "題組"
          ? this.state.mockType
          : "",
      mock_category:
        isArticle || this.props.paperSettings.type === "題組"
          ? this.state.mockCategory
          : "",
    };
    return masterQuestion;
  }

  setQuestionObject(questionObject) {
    var headerEditorState = this.getEditorState(questionObject["header"]);
    var descriptionEditorState = this.getEditorState(
      questionObject["description"]
    );
    questionObject["headerEditorState"] = headerEditorState;
    questionObject["descriptionEditorState"] = descriptionEditorState;
    questionObject["correctAnswer"] = questionObject["correct_answer"];
    questionObject["mockType"] = questionObject["mock_type"] ?? "";
    questionObject["mockCategory"] = questionObject["mock_category"] ?? "";
    this.questionImage.current.setImage(questionObject.image);
    this.setState(questionObject);
  }

  getTitle() {
    if (this.isArticle()) {
      return `問答${this.props.index + 1}`;
    }
    return `題目${this.props.index + 1}`;
  }

  isArticle() {
    return (
      this.props.paperSettings.type === "題組" || this.props.mockType === "題組"
    );
  }

  isNoAnswer() {
    return (
      this.state.answers["A"] === "" &&
      this.state.answers["B"] === "" &&
      this.state.answers["C"] === "" &&
      this.state.answers["D"] === ""
    );
  }

  getSequencedComponents() {
    var components = {
      題: (
        <Editor
          key={"question-header-editor"}
          toolbar={{
            options: ["inline"],
            inline: {
              options: ["bold", "underline"],
              bold: { icon: BoldIcon, className: "drafjs-custom-icon" },
              underline: {
                icon: UnderlineIcon,
                className: "drafjs-custom-icon",
              },
            },
          }}
          placeholder="題幹"
          ref={this.setHeaderEditor}
          editorState={this.state.headerEditorState}
          wrapperClassName="editor-wrapper"
          editorClassName="text-field"
          onEditorStateChange={this.onHeaderChanged}
        />
      ),
      引: (
        <Editor
          key={"question-description-editor"}
          toolbar={{
            options: ["inline"],
            inline: {
              options: ["bold", "underline"],
              bold: { icon: BoldIcon, className: "drafjs-custom-icon" },
              underline: {
                icon: UnderlineIcon,
                className: "drafjs-custom-icon",
              },
            },
          }}
          placeholder="引文"
          ref={this.setDescriptionEditor}
          editorState={this.state.descriptionEditorState}
          wrapperClassName="editor-wrapper"
          editorClassName="text-field"
          onEditorStateChange={this.onDescriptionChanged}
        />
      ),
      圖: (
        <DragAndDropUploadImage
          key={"question-drag-and-drop-upload-image"}
          ref={this.questionImage}
          callback={image => {
            this.setState({ image: image });
          }}
        />
      ),
    };

    // return sorted components based on sortation

    var sortedComponents = [];
    var sortation = this.state.sortation;
    for (var i = 0; i < sortation.length; i++) {
      sortedComponents.push(components[sortation[i]]);
    }
    return sortedComponents;

    /* <TextField
    value={this.state.description}
    variant={this.props.textFieldStyle}
    multiline
    fullWidth
    label="引文"
    onChange={e => this.saveValue("description", e.target.value)}
  /> */
  }

  render() {
    return (
      <div className="question-container">
        <div className="master-question-category-options">
          {/* {this.isArticle() &&
            Object.keys(PaperStructure).map(eachType => {
              if (eachType === "題組" || eachType === "模擬考") {
                return null;
              }
              return (
                <FlatButton
                  key={eachType}
                  label={eachType}
                  mini={true}
                  fit={true}
                  curve={true}
                  callback={() => {
                    this.setState({ mockType: eachType });
                  }}
                  active={this.state.mockType === eachType}
                />
              );
            })} */}
        </div>
        {/* <div className="master-question-category-options">
          {this.state.mockType !== "" &&
            this.isArticle() &&
            PaperStructure[this.state.mockType].map(eachCategory => {
              if (eachCategory === "檢測考題") {
                return null;
              }
              return (
                <FlatButton
                  key={eachCategory}
                  label={eachCategory}
                  mini={true}
                  fit={true}
                  curve={true}
                  callback={() => {
                    this.setState({ mockCategory: eachCategory });
                  }}
                  active={
                    this.state.mockCategory === eachCategory ? true : false
                  }
                />
              );
            })}
        </div> */}
        <div
          className={`question-title-bar rel ${
            this.isArticle() ? "" : "hide"
          }`}>
          {this.isArticle() && (
            <div className="abs-start master-question-category-options">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel htmlFor="grouped-native-select">
                  單題類型
                </InputLabel>
                <Select
                  variant="outlined"
                  sx={{
                    color: "#1781BC",
                    "& .MuiSvgIcon-root": {
                      color: "#1781BC",
                    },
                  }}
                  native
                  value={JSON.stringify({
                    type: this.state.mockType,
                    category: this.state.mockCategory,
                  })}
                  id="grouped-native-select"
                  onChange={e => {
                    let value = JSON.parse(e.target.value);
                    this.setState({
                      mockType: value.type,
                      mockCategory: value.category,
                    });
                  }}
                  label="考題類型">
                  <option aria-label="None" value="" />
                  {Object.keys(PaperStructure).map((eachType, i) => {
                    if (eachType === "模擬考" || eachType === "題組") {
                      return null;
                    }
                    return (
                      <optgroup
                        label={eachType}
                        key={`question-typeGrp-${eachType}-${i}`}>
                        {PaperStructure[eachType].map((eachCategory, j) => {
                          if (eachCategory === "檢測考題") {
                            return null;
                          }
                          return (
                            <option
                              key={`category-${eachCategory}-${j}`}
                              value={JSON.stringify({
                                type: eachType,
                                category: eachCategory,
                              })}>
                              {eachCategory}
                            </option>
                          );
                        })}
                      </optgroup>
                    );
                  })}
                  {/* <optgroup label="Category 2">
                  <option value={3}>Option 3</option>
                  <option value={4}>Option 4</option>
                </optgroup> */}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="question-title">{this.getTitle()}</div>
          <div className={`abs-end ${this.props.index === 0 ? "hide" : ""}`}>
            <GenericIconButton
              icon={DeletePaperIcon}
              size={16}
              callback={() => {
                ShowDialog(
                  GetDialogPack({
                    showDialog: true,
                    dialogType: DialogType.DELETE,
                    dialogTitle: "刪除",
                    dialogMessage: `是否確定刪除此${
                      this.isArticle() ? "問答" : "考題"
                    }？`,
                    dialogShowPrimaryButton: true,
                    dialogPrimaryButtonLabel: "確認",
                    dialogPrimaryButtonCallback: this.props.deleteQuestion,
                    dialogShowSecondaryButton: true,
                    dialogSecondaryButtonLabel: "返回",
                    dialogSecondaryButtonCallback: HideDialog,
                    dialogShowCheckBox: true,
                    dialogCheckBoxMessage: "是的，我要刪除",
                  })
                );
              }}
            />
          </div>
        </div>
        {this.getSequencedComponents()}
        <ButtonGroup variant="outlined" aria-label="排列方式" fullWidth={true}>
          {["題圖引", "題引圖", "引題圖", "引圖題", "圖題引", "圖引題"].map(
            (each, i) => (
              <Button
                onClick={() => {
                  this.setState({ sortation: each });
                }}
                key={`sortation-${each}-${i}`}
                variant={
                  this.state.sortation === each ? "contained" : "outlined"
                }
                color={this.state.sortation === each ? "success" : "secondary"}>
                {each}
              </Button>
            )
          )}
        </ButtonGroup>
        {this.isNoAnswer() && (
          <FlatButton
            fit={true}
            min={true}
            curve={true}
            label="快速甲乙丙丁"
            callback={() => {
              this.saveAnswer("A", "甲");
              this.saveAnswer("B", "乙");
              this.saveAnswer("C", "丙");
              this.saveAnswer("D", "丁");
            }}
          />
        )}
        <FormControl fullWidth>
          <RadioGroup
            row
            onChange={this.setCorrectAnswer}
            value={this.state.correctAnswer}>
            <div className="gap-v-m full-width">
              {AnswerTags.map(eachAnswerTag => {
                return (
                  <div key={`answer-${eachAnswerTag}`} className="h full-width">
                    <FormControlLabel
                      value={eachAnswerTag}
                      control={<Radio />}
                    />
                    <div className="question-answer-label unselectable">{`${eachAnswerTag.toUpperCase()}`}</div>
                    <TextField
                      value={this.state.answers[eachAnswerTag]}
                      fullWidth
                      multiline
                      label={`選項內容`}
                      onChange={e => {
                        this.saveAnswer(eachAnswerTag, e.target.value);
                      }}
                      variant="filled"
                    />
                  </div>
                );
              })}
            </div>
          </RadioGroup>
        </FormControl>

        <TextField
          value={this.state.explanation}
          fullWidth
          label="詳解"
          variant={this.props.textFieldStyle}
          multiline
          onChange={e => this.saveValue("explanation", e.target.value)}
        />
        <TextField
          value={this.state.source}
          fullWidth
          label="出處"
          variant={this.props.textFieldStyle}
          multiline
          onChange={e => this.saveValue("source", e.target.value)}
        />
      </div>
    );
  }
}

Question.propTypes = {
  paperSettings: PropTypes.object,
  masterQuestion: PropTypes.object,
};

Question.defaultProps = {
  paperSettings: { type: "" },
  masterQuestion: {},
  mockType: "",
};

export default Question;
