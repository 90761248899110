import React from "react";
import "./CreateNewPaperPage.scss";
import Logo from "../../assets/images/creator_logo.png";
import { PaperStructure } from "../../configs/PaperConstants";
import { adminCreatePaper } from "../../utilities/Requests";
import {
  HideDialog,
  HideLoading,
  ShowDialog,
  ShowLoading,
} from "../../utilities/EventBus";
import APIResult from "../../configs/APIResult";
import { DialogType, GetDialogPack } from "../../configs/DialogConstants";
import withRouter from "../../utilities/withRouter";

class CreateNewPaperPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentType: Object.keys(PaperStructure)[0],
      currentCategory: PaperStructure[Object.keys(PaperStructure)[0]][0],
      title: "",
      difficulty: "0",
      allowDifficulty:
        PaperStructure[Object.keys(PaperStructure)[0]][0] !== "檢測考題",
    };

    this.changeType = this.changeType.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeDifficulty = this.changeDifficulty.bind(this);
    this.ableToCreateNewPaper = this.ableToCreateNewPaper.bind(this);
    this.createNewPaper = this.createNewPaper.bind(this);
  }

  componentDidMount() {
    document.title = "創建考卷";
    this.checkAllowDifficulty();
  }

  changeType(e) {
    this.setState({
      currentType: e.target.value,
      allowDifficulty: false,
      difficulty: "0",
    });
  }

  changeCategory(e) {
    this.setState(
      {
        currentCategory: e.target.value,
      },
      this.checkAllowDifficulty
    );
  }

  checkAllowDifficulty() {
    let isGeneric = this.state.currentCategory === "檢測考題";

    this.setState({
      difficulty: isGeneric ? "0" : this.state.difficulty,
      allowDifficulty: !isGeneric,
    });
  }

  changeName(e) {
    this.setState({ title: e.target.value });
  }

  changeDifficulty(e) {
    this.setState({ difficulty: e.target.value });
  }

  ableToCreateNewPaper() {
    return this.state.title.replace(" ", "").length > 0;
  }

  async createNewPaper(e) {
    if (this.ableToCreateNewPaper()) {
      ShowLoading("正在創建新考卷...請稍候...");
      let body = {
        type: this.state.currentType,
        category: this.state.currentCategory,
        title: this.state.title,
        publish: false,
        difficulty: this.state.difficulty,
      };

      let adminCreatePaperResult = await adminCreatePaper(body);

      if (adminCreatePaperResult.status === APIResult.SUCCESS) {
        HideLoading();

        ShowDialog(
          GetDialogPack({
            showDialog: true,
            dialogType: DialogType.SUCCESS,
            dialogTitle: "上傳成功",
            dialogMessage: "資料已更新成功！",
            dialogShowPrimaryButton: true,
            dialogPrimaryButtonLabel: "返回",
            dialogPrimaryButtonCallback: () => {
              HideDialog();
              this.props.navigate(
                `/edit-paper-question/${adminCreatePaperResult.id}`
              );
              window.location.reload();
            },
            dialogShowSecondaryButton: false,
            dialogSecondaryButtonLabel: "",
            dialogSecondaryButtonCallback: () => {},
          })
        );
      }
    }
  }

  render() {
    return (
      <div className="parent">
        <div className="title">創建考卷</div>
        <div className="creator-parent">
          <div className="creator-banner">
            <img className="logo" src={Logo} alt="" />
            <div className="dropdown-parent">
              <div>
                <label htmlFor="selector">題型</label>
                <select
                  onChange={this.changeType}
                  id="questions"
                  title="questions">
                  {Object.keys(PaperStructure).map(option => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label htmlFor="selector">測驗</label>
                <select
                  onChange={this.changeCategory}
                  id="questions"
                  title="questions">
                  {PaperStructure[this.state.currentType].map(option => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div
                style={{
                  pointerEvents: this.state.allowDifficulty ? "auto" : "none",
                  opacity: this.state.allowDifficulty ? 1 : 0.75,
                  filter: `saturate(${this.state.allowDifficulty ? 1 : 0})`,
                }}>
                <label htmlFor="selector">難度</label>
                <select
                  onChange={this.changeDifficulty}
                  id="questions"
                  title="questions"
                  value={this.state.difficulty}>
                  {[0, 1, 2, 3, 4, 5].map(option => {
                    return (
                      <option key={option} value={option}>
                        {"★".repeat(option)}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label htmlFor="selector">名稱</label>
                <input
                  className="paper-title-input"
                  onChange={this.changeName}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`creator-button unselectable ${
            this.ableToCreateNewPaper() ? "" : "button-disabled"
          }`}
          onClick={this.createNewPaper}>
          確認創建
        </div>
      </div>
    );
  }
}

export default withRouter(CreateNewPaperPage);
