import React from "react";
import PropTypes from "prop-types";
import { debugPrint } from "../../utilities/Utilities";
import "./LoadingScreen.scss";
import { ProgressBar } from "react-loader-spinner";

class LoadingScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="loading-screen-parent">
        <ProgressBar
          height="80"
          width="80"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass="progress-bar-wrapper"
          borderColor="#1781BC"
          barColor="#E8F6FB"
        />
        <div className="loading-screen-label">{this.props.label}</div>
      </div>
    );
  }
}

LoadingScreen.propTypes = {
  label: PropTypes.string,
  callback: PropTypes.func,
};

LoadingScreen.defaultProps = {
  label: "LoadingScreen",
  callback: () => {
    debugPrint("LoadingScreen pressed");
  },
};

export default LoadingScreen;
