import React from "react";
import PropTypes from "prop-types";
import { debugPrint } from "../../utilities/Utilities";
import "./SidebarButton.scss";

class SidebarButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        className={`sidebar-option unselectable ${
          this.props.active ? "active" : ""
        }`}
        key={`${this.props.label}`}
        onClick={this.props.callback}>
        <div className="sidebar-option-text">{this.props.label}</div>
      </div>
    );
  }
}

SidebarButton.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
  callback: PropTypes.func,
};

SidebarButton.defaultProps = {
  label: "SidebarButton",
  active: false,
  callback: () => {
    debugPrint("SidebarButton pressed");
  },
};

export default SidebarButton;
