import React from "react";
import PropTypes from "prop-types";
import { debugPrint } from "../../utilities/Utilities";
import GenericButton from "./GenericButton";
import "./Sidebar.scss";
import ArrowDownIcon from "../../assets/images/arrow_down.svg";
import ArrowUpIcon from "../../assets/images/arrow_up.svg";
import { PaperStructure } from "../../configs/PaperConstants";
import { EventBus } from "../../utilities/EventBus";
import { adminGetPaperList } from "../../utilities/Requests";
import APIResult from "../../configs/APIResult";
import SidebarButton from "./SidebarButton";
import withRouter from "../../utilities/withRouter";
import { createBrowserHistory } from "@remix-run/router";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentExpandedType: {},
      paperList: {},
      currentPaper: "",
    };
    this.eventBusInitialized = false;

    this.listPaper = this.listPaper.bind(this);
  }

  componentDidMount() {
    this.listPaper();
    this.setState({ currentPaper: this.props.params["id"] ?? "" });
    this.initiateEventBus();
  }

  initiateEventBus() {
    if (!this.eventBusInitialized) {
      EventBus.on("list-paper", this.listPaper);
      this.eventBusInitialized = true;
    }
  }

  componentWillUnmount() {
    EventBus.remove("list-paper");
  }

  async listPaper() {
    let listPaperResult = await adminGetPaperList();
    if (listPaperResult.status === APIResult.SUCCESS) {
      let papers = listPaperResult.data;
      var paperList = {};
      papers.forEach(eachPaper => {
        if (!Object.keys(paperList).includes(eachPaper.type)) {
          paperList[eachPaper.type] = [eachPaper];
        } else {
          paperList[eachPaper.type].push(eachPaper);
        }
      });

      this.setState({ paperList: paperList });
    }
  }

  navigateToPage(page) {
    createBrowserHistory().push(`/edit-paper-question/${page}`);
    // window.location.reload();
    EventBus.dispatch("edit-paper-question", { page: page });
    this.setState({ currentPaper: page });
    if (page === "new") {
      document.title = "創建考卷";
    }
  }

  render() {
    return (
      <div className="">
        <div className="sidebar right-border">
          <div>
            <GenericButton
              label="+創建考卷"
              color="#F7764E"
              border="1px solid #EBAB41"
              spacing="0.3em"
              callback={() => {
                this.navigateToPage("new");
              }}
            />
            {Object.keys(PaperStructure).map(eachType => {
              return (
                <div key={eachType}>
                  <div
                    className="sidebar-buttons unselectable"
                    onClick={() => {
                      var currentExpandedType = this.state.currentExpandedType;
                      currentExpandedType[eachType] =
                        !currentExpandedType[eachType];
                      this.setState({
                        currentExpandedType: currentExpandedType,
                      });
                    }}>
                    {eachType}
                    <img
                      alt="expand collapse"
                      src={
                        !this.state.currentExpandedType[eachType]
                          ? ArrowDownIcon
                          : ArrowUpIcon
                      }
                    />
                  </div>

                  {Object.keys(this.state.paperList).includes(eachType) &&
                  !this.state.currentExpandedType[eachType]
                    ? this.state.paperList[eachType].map(eachPaper => (
                        <SidebarButton
                          key={eachPaper._id}
                          label={`${eachPaper.category} - ${eachPaper.title}`}
                          active={this.state.currentPaper === eachPaper._id}
                          callback={() => {
                            this.navigateToPage(eachPaper._id);
                          }}
                        />
                      ))
                    : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  label: PropTypes.string,
  callback: PropTypes.func,
};

Sidebar.defaultProps = {
  label: "Sidebar",
  callback: () => {
    debugPrint("Sidebar pressed");
  },
};

export default withRouter(Sidebar);
