import React from "react";
import PropTypes from "prop-types";
import {
  debugPrint,
  getCleanNumber,
  getRealCategory,
  getRealType,
  htmlHasContent,
  replaceContextForUnderline,
} from "../../utilities/Utilities";
import EditIcon from "../../assets/images/options_icon.svg";
import "./QuestionPreview.scss";
import EditQuestionIconOff from "../../assets/images/edit_paper_icon.svg";
import DeleteQuestionIconOff from "../../assets/images/delete_paper_icon.svg";
import {
  HideDialog,
  HideLoading,
  ShowDialog,
  ShowLoading,
} from "../../utilities/EventBus";
import {
  DialogType,
  GetDialogPack,
  ShowMessage,
} from "../../configs/DialogConstants";
import APIResult from "../../configs/APIResult";
import { adminDeleteQuestion } from "../../utilities/Requests";
import APIPaths from "../../configs/APIPaths";
import { checkIfStringIsNotEmpty } from "../../utilities/Utilities";

class QuestionPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showExtraMenu: false,
    };

    this.deleteQuestion = this.deleteQuestion.bind(this);
  }

  async deleteQuestion() {
    const question = this.props.question;
    HideDialog();
    ShowLoading("刪除考題中...請稍候...");
    let result = await adminDeleteQuestion(question._id);
    HideLoading();
    if (result.status === APIResult.SUCCESS) {
      ShowMessage(DialogType.SUCCESS, "考題已被刪除", () => {
        HideDialog();
        window.location.reload();
      });
    } else {
      ShowMessage(DialogType.FAILED, "刪除考題流程發生了錯誤。請稍候再試。");
    }
  }

  isArticle() {
    const question = this.props.question;
    return question.type === "題組" || question.mock_type === "題組";
  }

  getSortedComponents(eachQuestion) {
    let components = {
      題: (
        <p
          key={eachQuestion._id + "header"}
          className="question-preview-question"
          dangerouslySetInnerHTML={{
            __html: eachQuestion.header,
          }}></p>
      ),
      引: htmlHasContent(eachQuestion.description) ? (
        <p
          key={eachQuestion._id + "description"}
          className="question-preview-description"
          dangerouslySetInnerHTML={{
            __html: eachQuestion.description,
          }}></p>
      ) : null,
      圖:
        eachQuestion.image !== "" ? (
          <div
            key={`${eachQuestion._id}-question-preview-id`}
            className="question-preview-context-image-container">
            <img
              alt="context"
              className="question-preview-context-image"
              src={APIPaths.publicEndPoint + "images/" + eachQuestion.image}
            />
          </div>
        ) : null,
    };

    var sortedComponents = [];
    var sortation = eachQuestion.sortation ?? "題圖引";
    //return component in order
    for (var i = 0; i < sortation.length; i++) {
      sortedComponents.push(components[sortation[i]]);
    }

    return sortedComponents;
  }

  getRateLeft(questionID, answer) {
    const accuracyData = this.props.accuracyData ?? {};
    return getCleanNumber(
      accuracyData[questionID]["picked" + answer] /
        accuracyData[questionID].total
    );
  }

  render() {
    const question = this.props.question;
    const accuracyData = this.props.accuracyData ?? {};
    const color = this.props.color ?? "";
    const accuracyMode = Object.keys(accuracyData).length > 0;
    return (
      <div className="question-preview-backdrop">
        <div
          className=" gap-v-m"
          onClick={() => {
            this.setState({
              showExtraMenu: false,
            });
          }}>
          {this.props.isFull && question.context.length > 7 ? (
            <div className="question-preview-context">
              <p
                className={`question-with-answer-context single`}
                dangerouslySetInnerHTML={{
                  __html: replaceContextForUnderline(question.context),
                }}></p>
              <p className="quote">{question.quote}</p>
            </div>
          ) : null}
          {this.props.isFull && question.image !== "" ? (
            <div className="question-preview-context-image-container">
              <img
                alt="context"
                className="question-preview-context-image"
                src={APIPaths.publicEndPoint + "images/" + question.image}
              />
            </div>
          ) : null}
          <div className="gap-v-m">
            {question.all_questions.map((eachQuestion, i) => {
              return (
                <div
                  key={`${question._id}-question-${i}`}
                  className="question-preview-question-container">
                  <p
                    className="question-preview-question-index"
                    key={`index-${question._id}-question-${i}`}>
                    {this.props.index + i}.
                  </p>
                  <div className="gap-v-m full-width">
                    {this.getSortedComponents(eachQuestion)}
                    {Object.keys(eachQuestion.answers)
                      .sort()
                      .map(eachAnswer => {
                        return (
                          <div
                            key={`${eachQuestion._id} - ${eachAnswer}`}
                            className="question-preview-answer-container">
                            <div
                              className={`gap-v-m question-preview-answer-index ${
                                eachQuestion.correct_answer === eachAnswer &&
                                !accuracyMode
                                  ? "active"
                                  : ""
                              }`}>
                              {eachAnswer}
                            </div>
                            <p className="edusong">
                              {eachQuestion.answers[eachAnswer]}
                            </p>
                          </div>
                        );
                      })}

                    <div className="question-preview-explanation-container">
                      {!accuracyMode ? (
                        <>
                          <div className="question-preview-explanation-title">
                            <p className="">題目詳解</p>
                            <div className="h gap-v-s">
                              {this.isArticle() &&
                                getRealType(eachQuestion, question) !==
                                  "題組" && (
                                  <p className="question-preview-explanation-type tone-down">
                                    {getRealType(eachQuestion, question)}
                                  </p>
                                )}
                              <p className="question-preview-explanation-type">
                                {getRealCategory(eachQuestion, question)}
                              </p>
                            </div>
                          </div>
                          <p className="question-preview-explanation-answer">
                            {eachQuestion.correct_answer === 0
                              ? "未選擇答案"
                              : `這題答案是${eachQuestion.correct_answer}。`}
                          </p>
                          <p className="question-preview-explanation-details">
                            {eachQuestion.explanation}
                          </p>
                          {checkIfStringIsNotEmpty(eachQuestion.source) && (
                            <p className="question-preview-source">
                              {eachQuestion.source}
                            </p>
                          )}
                        </>
                      ) : (
                        <div className="question-preview-accuracy">
                          <div className="question-preview-accuracy-wrapper">
                            {["A", "B", "C", "D"].map(eachAnswer => {
                              return (
                                <div
                                  className="answer-rate"
                                  key={`accuracy-each-${eachAnswer}`}>
                                  <p
                                    className={`tag ${color} ${
                                      eachQuestion.correct_answer === eachAnswer
                                        ? "active"
                                        : ""
                                    }`}>
                                    {eachAnswer}
                                  </p>
                                  <div className="rate-bar">
                                    <div
                                      className={`rate-bar-left ${color} ${
                                        eachQuestion.correct_answer ===
                                        eachAnswer
                                          ? "active"
                                          : ""
                                      }`}
                                      style={{
                                        width: `${
                                          this.getRateLeft(
                                            eachQuestion._id,
                                            eachAnswer
                                          ) * 100
                                        }%`,
                                      }}></div>
                                    <div className="rate-bar-right"></div>
                                  </div>
                                  <p
                                    className={`rate-label ${color} ${
                                      eachQuestion.correct_answer === eachAnswer
                                        ? "active"
                                        : ""
                                    }`}>
                                    {(
                                      this.getRateLeft(
                                        eachQuestion._id,
                                        eachAnswer
                                      ) * 100
                                    ).toFixed(0)}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                          <div className="question-preview-accuracy-summary-wrapper">
                            <div className="question-with-answer-category-tag">
                              {getRealCategory(eachQuestion, question)}
                            </div>
                            <p>
                              <span>正答率 </span>
                              <span className="bold">
                                {this.getRateLeft(
                                  eachQuestion._id,
                                  accuracyData[eachQuestion._id].question
                                    .correct_answer
                                ).toFixed(2)}
                              </span>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {!accuracyMode && (
          <div className="question-preview-edit-container">
            <img
              alt="menu-icon"
              className="question-preview-edit-button"
              src={EditIcon}
              onClick={() => {
                this.setState({
                  showExtraMenu: !this.state.showExtraMenu,
                });
              }}
            />
            {this.state.showExtraMenu ? (
              <div className="question-preview-edit-backdrop">
                <div
                  className="question-preview-edit-buttons-layout unselectable"
                  onClick={() => {
                    this.setState({ showExtraMenu: false });
                    this.props.triggerEditCallback();
                  }}>
                  <img src={EditQuestionIconOff} alt="edit question" />
                  <p className="question-preview-edit-buttons-text">編輯題目</p>
                </div>

                <div
                  className="question-preview-edit-buttons-layout"
                  onClick={() => {
                    this.setState({ showExtraMenu: false });
                    ShowDialog(
                      GetDialogPack({
                        showDialog: true,
                        dialogType: DialogType.DELETE,
                        dialogTitle: "刪除考題",
                        dialogMessage: "是否確定要刪除此考題？",
                        dialogShowPrimaryButton: true,
                        dialogPrimaryButtonLabel: "確認",
                        dialogPrimaryButtonCallback: this.deleteQuestion,
                        dialogShowSecondaryButton: true,
                        dialogSecondaryButtonLabel: "返回",
                        dialogSecondaryButtonCallback: HideDialog,
                        dialogShowCheckBox: true,
                        dialogCheckBoxMessage: "是的，我要刪除",
                      })
                    );
                  }}>
                  <img src={DeleteQuestionIconOff} alt="delete question" />
                  <p className="question-preview-edit-buttons-text">刪除題目</p>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

QuestionPreview.propTypes = {
  isFull: PropTypes.bool,
  label: PropTypes.string,
  callback: PropTypes.func,
  triggerEditCallback: PropTypes.func,
};

QuestionPreview.defaultProps = {
  isFull: false,
  question: "QuestionPreview",
  callback: () => {
    debugPrint("QuestionPreview pressed");
  },
  triggerEditCallback: () => {
    debugPrint("QuestionPreview pressed");
  },
};

export default QuestionPreview;
