const APIResult = {
  TIMED_OUT: -1,
  FAILED: 0,
  SUCCESS: 1,

  DATABASE_OFFLINE: -1001,
  INCOMPLETE_INPUT: -2001,
  INVALID_ID: -2002,
  ACCOUNT_EXISTS: -3001,
  INVALID_CREDENTIAL: -3002,
  TARGET_NOT_FOUND: -4001,

  // GET HOMEPAGE
  INVALID_SESSION: 1002,
};

export default APIResult;
