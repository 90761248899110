export const PaperStructure = {
  閱讀單題: ["檢測考題", "詮釋", "摘要", "推論", "統整", "分析"],
  語文: ["檢測考題", "字形", "讀音", "標點", "恰當詞語", "字體六書", "應用文"],
  題組: ["檢測考題", "訊息短文", "文學短文", "文言文", "韻文"],
  模擬考: [],
};

export const PaperSettings = {
  _id: "",
  type: "",
  category: "",
  title: "",
  questions: [],
  publish: 0,
  version: 0,
  update_time: 0,
  history: [],
  difficulty: 0,
};

export const AnswerTags = ["A", "B", "C", "D"];
// export const PaperStructure = {
//     綜合: {
//       id: 1000,
//       categories: {
//         檢測考題: 1001,
//         詮釋: 1002,
//         摘要: 1003,
//         推論: 1004,
//         統整: 1005,
//         分析: 1006,
//       },
//     },
//     語文: {
//       id: 2000,
//       categories: {
//         檢測考題: 2001,
//         字形: 2002,
//         讀音: 2003,
//         標點: 2004,
//         恰當詞語: 2005,
//         字體六書: 2006,
//         應用文: 2007,
//       },
//     },
//     題組: {
//       id: 3000,
//       categories: {
//         檢測考題: 3001,
//         訊息短文: 3002,
//         文學短文: 3003,
//         文言文: 3004,
//         韻文: 3005,
//       },
//     },
//     模擬考: {
//       id: 4000,
//       categories: { 綜合題: 4001, 文化題: 4002, 題組題: 4003 },
//     },
//   };
