import React from "react";
import PropTypes from "prop-types";
import { DialogPack, DialogType } from "../../configs/DialogConstants";
import DialogSuccessIcon from "../../assets/images/dialog_success_icon.png";
import DialogErrorIcon from "../../assets/images/dialog_error_icon.png";
import DialogMissingIcon from "../../assets/images/dialog_missing_icon.png";
import DialogDeleteIcon from "../../assets/images/dialog_delete_icon.png";
import DialogPublishIcon from "../../assets/images/dialog_publish_icon.png";
import "./DialogBox.scss";

class DialogBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      alert: 0,
    };
  }

  getIcon() {
    switch (this.props.dialogPack.dialogType) {
      default:
      case DialogType.SUCCESS:
        return DialogSuccessIcon;
      case DialogType.ERROR:
        return DialogErrorIcon;
      case DialogType.MISSING:
        return DialogMissingIcon;
      case DialogType.DELETE:
        return DialogDeleteIcon;
      case DialogType.PUBLISH:
        return DialogPublishIcon;
    }
  }

  getLabel() {
    switch (this.props.dialogPack.dialogType) {
      default:
      case DialogType.SUCCESS:
        return "Success";
      case DialogType.ERROR:
        return "Error";
      case DialogType.MISSING:
        return "Missing";
      case DialogType.DELETE:
        return "Delete";
      case DialogType.PUBLISH:
        return "Publish";
    }
  }

  render() {
    return (
      <div className="dialog-box-zone">
        <div className={`dialog-box-parent ${this.getLabel()}`}>
          <div className={`dialog-box-header ${this.getLabel()}`}>
            <img alt="icon" className="dialog-box-icon" src={this.getIcon()} />
            <div className="dialog-box-label">{this.getLabel()}</div>
            <div className="dialog-box-title-parent">
              <div className="dialog-box-title">
                {this.props.dialogPack.dialogTitle}
              </div>
            </div>
          </div>
          <div className="dialog-box-content center">
            <div>{this.props.dialogPack.dialogMessage}</div>
            {this.props.dialogPack.dialogListInfo.length > 0 && (
              <div className="dialog-box-list-info-container ">
                {this.props.dialogPack.dialogListInfo.map((eachInfo, i) => (
                  <div key={`dialog-info-${i}`} className="h flex">
                    <p className="dialog-box-list-info-index">{i + 1}.</p>
                    <p>{eachInfo}</p>
                  </div>
                ))}
              </div>
            )}
            {this.props.dialogPack.dialogShowCheckBox && (
              <div
                key={this.state.alert}
                className={`center s-g checkbox-wrapper-40 ${
                  this.state.alert > 0 ? "shake" : ""
                }`}>
                <label className="center">
                  <input
                    type="checkbox"
                    onChange={e => {
                      this.setState({ checked: e.target.checked });
                    }}
                  />
                  {/* <span class="checkbox"></span> */}
                </label>
                <div className="dialog-box-checkbox-message">
                  {this.props.dialogPack.dialogCheckBoxMessage}
                </div>
              </div>
            )}
            <div className="dialog-box-buttons-parent">
              {this.props.dialogPack.dialogShowPrimaryButton && (
                <div
                  className={`dialog-box-button primary ${this.getLabel()}`}
                  onClick={() => {
                    if (this.props.dialogPack.dialogShowCheckBox) {
                      if (!this.props.dialogPack.dialogSecondaryAsRealMain) {
                        if (!this.state.checked) {
                          this.setState({ alert: Date.now() });
                          return;
                        }
                      }
                    }
                    this.props.dialogPack.dialogPrimaryButtonCallback();
                  }}>
                  {this.props.dialogPack.dialogPrimaryButtonLabel}
                </div>
              )}
              {this.props.dialogPack.dialogShowSecondaryButton && (
                <div
                  className={`dialog-box-button secondary ${this.getLabel()}`}
                  onClick={() => {
                    if (this.props.dialogPack.dialogSecondaryAsRealMain) {
                      if (!this.state.checked) {
                        this.setState({ alert: Date.now() });
                        return;
                      }
                    }
                    this.props.dialogPack.dialogSecondaryButtonCallback();
                  }}>
                  {this.props.dialogPack.dialogSecondaryButtonLabel}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DialogBox.propTypes = {
  dialogPack: PropTypes.object,
};

DialogBox.defaultProps = {
  dialogPack: DialogPack,
};

export default DialogBox;
