import React from "react";
import "./Sortation.scss";
import {
  EventBus,
  HideDialog,
  HideLoading,
  ShowLoading,
} from "../../utilities/EventBus";
import APIResult from "../../configs/APIResult";
import { DialogType, ShowMessage } from "../../configs/DialogConstants";
import { adminSetPaperQuestionsSortation } from "../../utilities/Requests";

class Sortation extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hover: "" };

    this.newSortation = [];
  }

  getQuestion(question) {
    if (question.context !== "") {
      return question.context;
    }
    if (question.all_questions.length > 0) {
      return question.all_questions[0].header;
    }
  }

  isFull(question) {
    return question.context !== "";
  }

  selectUnselectAll(select) {
    const { paperSettings } = this.props;
    var questionsState = { allChecked: select };
    for (var eachQuestion in paperSettings.questions) {
      questionsState[paperSettings.questions[eachQuestion]._id] = select;
    }
    this.setState(questionsState);
    this.draggingItem = React.createRef();
    this.dragOverItem = React.createRef();
  }

  handleDragStart = (e, id) => {
    this.draggingItem = id;
  };

  handleDragEnter = (e, id) => {
    e.preventDefault();
    this.dragOverItem = id;
    const { sortation } = this.props;
    let draggingItem = this.draggingItem;
    let draggingOverItem = this.dragOverItem;

    if (draggingItem === draggingOverItem) {
      this.newSortation = sortation;
      return;
    }

    // Get the indices of the dragging item and the item being dragged over
    let draggingItemIndex = sortation.findIndex(
      eachQuestion => eachQuestion === draggingItem
    );
    let draggingOverItemIndex = sortation.findIndex(
      eachQuestion => eachQuestion === draggingOverItem
    );

    if (draggingItemIndex < draggingOverItemIndex) {
      this.newSortation = sortation.filter(
        eachQuestion => eachQuestion !== draggingItem
      );
      this.newSortation.splice(draggingOverItemIndex, 0, draggingItem);
    } else {
      // If draggingItemIndex is greater than draggingOverItemIndex, insert draggingItem after draggingOverItem
      this.newSortation = sortation.filter(
        eachQuestion => eachQuestion !== draggingItem
      );
      this.newSortation.splice(draggingOverItemIndex + 1, 0, draggingItem);
    }

    console.log(draggingItemIndex, draggingOverItemIndex, this.newSortation);
    this.setState({ hover: id });
  };

  async updateSortation(status) {
    const { paperSettings } = this.props;
    ShowLoading("更新發布狀態中...");
    var ids = [];
    for (let eachQuestion in Object.keys(this.state)) {
      let id = Object.keys(this.state)[eachQuestion];
      if (this.state[id]) {
        ids.push(id);
      }
    }
    let result = await adminSetPaperQuestionsSortation(ids, status);
    HideLoading();
    if (result.status === APIResult.SUCCESS) {
      ShowMessage(DialogType.SUCCESS, "已更新發布狀態", () => {
        HideDialog();
        EventBus.dispatch("edit-paper-question", {
          page: paperSettings._id,
        });
      });
    } else {
      ShowMessage(
        DialogType.ERROR,
        "更新發布狀態的流程中出現了一些不知名錯誤。\n\n" +
          JSON.stringify(result)
      );
    }

    var toUpdate = { allChecked: false };
    paperSettings.questions.forEach(eachQuestion => {
      toUpdate[eachQuestion._id] = false;
    });

    this.setState(toUpdate);
  }

  currentSortedQuestions() {
    const { paperSettings, sortation } = this.props;
    var sortedQuestions = [...paperSettings.questions];
    if (sortation.length > 0) {
      sortedQuestions.sort((a, b) => {
        const indexA = sortation.indexOf(a._id.toString());
        const indexB = sortation.indexOf(b._id.toString());

        // Handle the case where an ID is not found in the sortation array
        if (indexA === -1 && indexB === -1) {
          return 0; // Both IDs not found, maintain their relative order
        } else if (indexA === -1) {
          return 1; // a._id not found, place it after b
        } else if (indexB === -1) {
          return -1; // b._id not found, place it after a
        } else {
          return indexA - indexB; // Both IDs found, compare their indices
        }
      });
    }
    return sortedQuestions;
  }

  render() {
    console.log(this.props.sortation);
    return (
      <div className="sortation-backdrop">
        <div
          className="flex-v "
          style={{
            gap: 8,
          }}>
          {this.currentSortedQuestions().map((eachQuestion, i) => (
            <div key={i} className="sortation-question">
              <div
                className="sortation-question-header"
                draggable
                style={{
                  opacity: this.state.hover === eachQuestion._id ? 0.5 : 1,
                }}
                onDragStart={e => this.handleDragStart(e, eachQuestion._id)}
                onDragOver={e => e.preventDefault()}
                onDragEnd={e => {
                  this.props.updateSortation(this.newSortation);
                  this.setState({ hover: "" });
                }}
                onDragEnter={e => this.handleDragEnter(e, eachQuestion._id)}>
                <div className="sortation-question-header-content">
                  <div className="sortation-question-header-index">{i + 1}</div>
                  <div
                    className="sortation-question-header-text"
                    dangerouslySetInnerHTML={{
                      __html: this.getQuestion(eachQuestion),
                    }}></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Sortation;
