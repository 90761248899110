export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
export function propComparator(prop) {
  return function (a, b) {
    return a[prop] - b[prop];
  };
}

export function debugPrint(...args) {
  if (isDebug) {
    console.log(...args);
  }
}

export function isDebug() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
}

export function makeID(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getBuffer(fileData) {
  return function (resolve) {
    var reader = new FileReader();
    reader.readAsArrayBuffer(fileData);
    reader.onload = function () {
      var arrayBuffer = reader.result;
      var bytes = new Uint8Array(arrayBuffer);
      resolve(bytes);
    };
  };
}

export function updateURLParameter(url, param, paramVal) {
  var newAdditionalURL = "";
  var tempArray = url.split("?");
  var baseURL = tempArray[0];
  var additionalURL = tempArray[1];
  var temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] !== param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  var rows_txt = temp + "" + param + "=" + paramVal;
  return baseURL + "?" + newAdditionalURL + rows_txt;
}

export function checkIfStringIsNotEmpty(str) {
  return str !== undefined && str !== null && str !== "";
}

export function getRealCategory(subQuestion, masterQuestion) {
  if (checkIfStringIsNotEmpty(subQuestion.mock_category)) {
    return subQuestion.mock_category;
  }
  if (checkIfStringIsNotEmpty(subQuestion.category)) {
    return subQuestion.category;
  }
  if (checkIfStringIsNotEmpty(masterQuestion.mock_category)) {
    return masterQuestion.mock_category;
  }
  if (checkIfStringIsNotEmpty(masterQuestion.category)) {
    return masterQuestion.category;
  }
  return "";
}

export function getRealType(subQuestion, masterQuestion) {
  if (checkIfStringIsNotEmpty(subQuestion.mock_type)) {
    return subQuestion.mock_type;
  }
  if (checkIfStringIsNotEmpty(subQuestion.type)) {
    return subQuestion.type;
  }
  if (checkIfStringIsNotEmpty(masterQuestion.mock_type)) {
    return masterQuestion.mock_type;
  }
  if (checkIfStringIsNotEmpty(masterQuestion.type)) {
    return masterQuestion.type;
  }
  return "";
}

export function checkIfStringIsHTML(str) {
  let doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

export function replaceContextForUnderline(context) {
  return context.replaceAll(
    "####",
    `<span class="no-letter-spacing">____</span>`
  );
}

export function epochToDateTime(epoch) {
  // return new Date(epoch).toLocaleString("zh-TW", {
  //   timeZone: "Asia/Taipei",
  // });

  // return 2022/10/15

  var date = new Date(epoch);
  var year = date.getFullYear();
  var month = date.getMonth() + 1; // add leading zero

  if (month < 10) {
    month = "0" + month;
  }
  var day = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  return `${year}/${month}/${day}`;
}

export function commafy(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function htmlHasContent(html) {
  // check if html code provided has any content, it can be <p></p> or <p>   </p>, which is empty
  // or <div><p>This is something</p></div>, which is not empty
  // or <div><p></p></div>, which is empty
  // or <div><p>   </p></div>, which is empty
  // or <div><p>   </p><p>   </p></div>, which is empty

  // parse html code into DOM
  var parser = new DOMParser();
  var doc = parser.parseFromString(html, "text/html");

  // get all the text nodes in the DOM, where it is <p> or <div> or <span>
  var textNodes = doc.querySelectorAll("p, div, span");

  // if there is no text node, then it is empty
  if (textNodes.length === 0) {
    return false;
  }
  // if there is text node, then check if it is empty
  else {
    // if there is any text node that is not empty, then it is not empty
    for (var i = 0; i < textNodes.length; i++) {
      if (textNodes[i].textContent.trim() !== "") {
        return true;
      }
    }
    // if all the text nodes are empty, then it is empty
    return false;
  }
}

export function getCleanNumber(number) {
  // if number is NaN, return 0
  if (isNaN(number)) {
    return 0;
  }
  // if number is not NaN, return the number
  else {
    return number;
  }
}
