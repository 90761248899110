import React from "react";
import PropTypes from "prop-types";
import { debugPrint } from "../../utilities/Utilities";
import "./TextTab.scss";

class TextTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSelected: 0,
    };
  }

  render() {
    return (
      <div className="text-tab-container unselectable">
        {this.props.labels.map((eachLabel, i) => (
          <div
            className={`text-tab ${
              i === this.props.currentTab ? "active" : ""
            }`}
            style={
              i === this.props.labels.length - 1 ? { border: "unset" } : {}
            }
            key={eachLabel}
            onClick={() => {
              // this.setState({ currentTab: i });
              this.props.callback(i);
            }}>
            {eachLabel}
          </div>
        ))}
      </div>
    );
  }
}

TextTab.propTypes = {
  labels: PropTypes.array,
  callback: PropTypes.func,
};

TextTab.defaultProps = {
  labels: ["1", "2", "3"],
  callback: e => {
    debugPrint("TextTab pressed");
  },
};

export default TextTab;
