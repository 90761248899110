import { DialogPack } from "../configs/DialogConstants";

export const EventBus = {
  on(event, callback) {
    document.addEventListener(event, e => callback(e.detail));
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};

export const ShowLoading = message => {
  console.log("show loading");
  EventBus.dispatch("loading", { showLoading: true, loadingMessage: message });
};

export const HideLoading = () => {
  EventBus.dispatch("loading", { showLoading: false, loadingMessage: "" });
};

export const ShowDialog = dialogPack => {
  EventBus.dispatch("dialog", { dialogPack: dialogPack });
};

export const HideDialog = () => {
  EventBus.dispatch("dialog", { dialogPack: DialogPack });
};
