const APIPaths = {
  apiEndPoint: "https://api.jyreading.com/",
  publicEndPoint: "https://public.jyreading.com/",
  // apiEndPoint: "http://localhost:15080/",
  adminSignIn: "api/admin/sign_in",
  adminUploadImage: "api/admin/upload_image",
  adminCreatePaper: "api/admin/create_paper",
  adminGetPaperList: "api/admin/get_paper_list",
  adminReadPaper: "api/admin/read_paper",
  adminUpdatePaper: "api/admin/update_paper",
  adminUpdatePapersPublishStatus: "api/admin/update_papers_publish_status",
  adminDeletePaper: "api/admin/delete_paper",
  adminCreateQuestions: "api/admin/create_questions",
  adminUpdateQuestion: "api/admin/update_question",
  adminUpdateQuestionsPublishStatus:
    "api/admin/update_questions_publish_status",
  clientGetPaperQuestionsSortation: "api/client/get_paper_questions_sortation",
  adminSetPaperQuestionsSortation: "api/admin/set_paper_questions_sortation",
  adminDeleteQuestion: "api/admin/delete_question",
  adminGetStatisticsData: "api/admin/get_statistics_data",
};

export default APIPaths;
