import React from "react";
import PropTypes from "prop-types";
import { debugPrint } from "../../utilities/Utilities";
import "./GenericIconButton.scss";

class GenericIconButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="icon-button" onClick={this.props.callback}>
        <img
          alt="icon button"
          src={this.props.icon}
          height={this.props.size}
          width={this.props.size}
        />
      </div>
    );
  }
}

GenericIconButton.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.number,
  callback: PropTypes.func,
};

GenericIconButton.defaultProps = {
  icon: "",
  size: 32,
  callback: () => {
    debugPrint("GenericIconButton pressed");
  },
};

export default GenericIconButton;
