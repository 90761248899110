import React from "react";
import PropTypes from "prop-types";
import { debugPrint } from "../../utilities/Utilities";
import "./LoginPage.scss";
import LoginImage from "../../assets/images/login.webp";
import { Button, TextField } from "@mui/material";
import { LoginRounded } from "@mui/icons-material";

import Tilt from "react-vanilla-tilt";
import {
  HideDialog,
  HideLoading,
  ShowDialog,
  ShowLoading,
} from "../../utilities/EventBus";
import { DialogType, GetDialogPack } from "../../configs/DialogConstants";
import { adminSignIn } from "../../utilities/Requests";
import APIResult from "../../configs/APIResult";
import withRouter from "../../utilities/withRouter";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { id: "", password: "" };

    this.saveValue = this.saveValue.bind(this);
    this.performLogin = this.performLogin.bind(this);
  }

  componentDidMount() {
    this.autoLogin();
  }

  saveValue(key, value) {
    this.setState({ [key]: value });
  }

  autoLogin() {
    try {
      if (localStorage.getItem("jyreadingQuizAC")) {
        // wait for 1 second to show loading
        ShowLoading("登入中...");
        setTimeout(() => {
          this.props.navigate("/edit-paper-question/new");
        }, 1000);
        HideLoading();
      }
    } catch (e) {
      debugPrint(e);
    }
  }

  async performLogin(_id = null, _password = null) {
    let id = this.state.id;
    let password = this.state.password;
    if (_id && _password) {
      id = _id;
      password = _password;
    }
    if (id.replace(/\s/g, "") === "" || password.replace(/\s/g, "") === "") {
      ShowDialog(
        GetDialogPack({
          showDialog: true,
          dialogType: DialogType.ERROR,
          dialogTitle: "錯誤",
          dialogMessage: `帳號或密碼不得為空白`,
          dialogShowPrimaryButton: true,
          dialogPrimaryButtonLabel: "返回",
          dialogPrimaryButtonCallback: HideDialog,
        })
      );
      return;
    }
    ShowLoading("登入中...");
    let result = await adminSignIn({
      id: id,
      pw: password,
    });
    HideLoading();
    if (result.status === APIResult.SUCCESS) {
      // save token into local storage
      // expected return
      // {
      //   "status": 1,
      //   "status_message": "SUCCESS",
      //   "session_id": "64a05dd108548cc78093c268",
      //   "session_token": "SygI9s4XmEYMbXzl"
      // }
      localStorage.setItem(
        "jyreadingQuizAC",
        JSON.stringify({
          sessionID: result.session_id,
          sessionToken: result.session_token,
        })
      );
      this.props.navigate("/edit-paper-question/new");
    } else {
      ShowDialog(
        GetDialogPack({
          showDialog: true,
          dialogType: DialogType.ERROR,
          dialogTitle: "錯誤",
          dialogMessage: `帳號或密碼錯誤`,
          dialogShowPrimaryButton: true,
          dialogPrimaryButtonLabel: "返回",
          dialogPrimaryButtonCallback: HideDialog,
        })
      );
    }
  }

  render() {
    return (
      <div className="container">
        <div className="wrap-login">
          <div className="login-image">
            <Tilt
              id="tilt"
              options={{ scale: 2, glare: false, perspective: 1000, max: 100 }}
              style={{
                width: "100%",
                backgroundColor: "#fff",
              }}>
              <img
                style={{ objectFit: "contain" }}
                height={"100%"}
                width={"100%"}
                src={LoginImage}
                alt="IMG"
              />
            </Tilt>
          </div>
          <div className="login-form">
            <h2>財團法人江雲教育基金會 後台</h2>
            <TextField
              value={this.state.id}
              variant="filled"
              fullWidth
              required
              label="帳號"
              onChange={e => this.saveValue("id", e.target.value)}
            />
            <TextField
              value={this.state.password}
              variant="filled"
              fullWidth
              required
              type="password"
              label="密碼"
              onChange={e => this.saveValue("password", e.target.value)}
            />
            <Button
              variant="contained"
              fullWidth
              disabled={!(this.state.id && this.state.password)}
              endIcon={<LoginRounded />}
              onClick={this.performLogin}>
              登入
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  label: PropTypes.string,
  callback: PropTypes.func,
};

LoginPage.defaultProps = {
  label: "LoginPage",
  callback: () => {
    debugPrint("LoginPage pressed");
  },
};

export default withRouter(LoginPage);
