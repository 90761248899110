import React from "react";
import PropTypes from "prop-types";
import { debugPrint, getBuffer } from "../../utilities/Utilities";
import FlatButton from "./FlatButton";
import { adminUploadImage } from "../../utilities/Requests";
import APIResult from "../../configs/APIResult";
import {
  HideDialog,
  HideLoading,
  ShowDialog,
  ShowLoading,
} from "../../utilities/EventBus";
import { DialogType, GetDialogPack } from "../../configs/DialogConstants";
import APIPaths from "../../configs/APIPaths";
import "./DragAndDropUploadImage.scss";
import GenericIconButton from "./GenericIconButton";
import DeletePaperIcon from "../../assets/images/delete_paper_icon.svg";

class DragAndDropUploadImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: "",
      oriImage: "",
      uploading: true,
    };

    this.input = React.createRef();
    this.handleDragOver = this.handleDragOver.bind(this);
    this.onFileDrop = this.onFileDrop.bind(this);
    this.onFileSelected = this.onFileSelected.bind(this);
    this.processFile = this.processFile.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.getImage = this.getImage.bind(this);
    this.setImage = this.setImage.bind(this);
    this.resetImage = this.resetImage.bind(this);
  }

  componentWillUnmount() {}

  handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onFileDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    if (files && files.length) {
      this.processFile(files[0]);
    }
  }

  onFileSelected(e) {
    this.processFile(e.target.files[0]);
  }

  processFile(f) {
    let outerThis = this;
    let fileData = new Blob([f]);
    var promise = new Promise(getBuffer(fileData));
    promise
      .then(function (data) {
        if (
          f.name.toLowerCase().endsWith(".png") ||
          f.name.toLowerCase().endsWith(".jpg") ||
          f.name.toLowerCase().endsWith(".jpeg")
        ) {
          outerThis.uploadFile(data, f.type);
        }
      })

      .catch(function (err) {
        console.log("Error: ", err);
      });
  }

  getImagePath() {
    return `${APIPaths.publicEndPoint}images/${this.state.currentImage}`;
  }

  async uploadFile(f, fileType) {
    ShowLoading("上傳圖檔中...請稍候...");
    let uploadImageResult = await adminUploadImage(f, fileType);
    HideLoading();
    console.log(uploadImageResult);
    if (uploadImageResult.status === APIResult.SUCCESS) {
      this.setImage(uploadImageResult.data);
    }
    if (uploadImageResult.status === APIResult.INCOMPLETE_INPUT) {
      ShowDialog(
        GetDialogPack({
          showDialog: true,
          dialogType: DialogType.ERROR,
          dialogTitle: "錯誤",
          dialogMessage: "無法確認圖檔內容，請上傳PNG或JPG格式圖檔",
          dialogShowPrimaryButton: true,
          dialogPrimaryButtonLabel: "返回",
          dialogPrimaryButtonCallback: () => {
            HideDialog();
          },
        })
      );
    }
    if (uploadImageResult.status === APIResult.TIMED_OUT) {
      ShowDialog(
        GetDialogPack({
          showDialog: true,
          dialogType: DialogType.ERROR,
          dialogTitle: "錯誤",
          dialogMessage: "上傳圖檔超時，請確認網路狀態後。",
          dialogShowPrimaryButton: true,
          dialogPrimaryButtonLabel: "返回",
          dialogPrimaryButtonCallback: () => {
            HideDialog();
          },
        })
      );
    }
  }

  getImage() {
    return this.state.currentImage;
  }

  setImage(img) {
    this.setState({
      currentImage: img,
      oriImage: this.state.oriImage === "" ? img : this.state.oriImage,
    });
    this.props.callback(img);
  }

  resetImage() {
    this.setImage(this.state.oriImage);
  }

  render() {
    return (
      <div
        className=""
        onDrop={this.onFileDrop}
        onDragOver={this.handleDragOver}>
        <input
          accept="image/png, image/jpeg"
          type="file"
          ref={this.input}
          onChange={this.onFileSelected}
          className="hide"
        />
        {this.state.currentImage === "" ? (
          <FlatButton
            label="＋上傳圖片"
            compact={true}
            callback={() => {
              this.input.current.click();
            }}
          />
        ) : (
          <div className="drag-and-drop-uploaded-image-container rel">
            <div className="abs-end">
              <GenericIconButton
                icon={DeletePaperIcon}
                size={18}
                callback={() => {
                  this.setImage("");
                }}
              />
            </div>
            <img src={this.getImagePath()} alt="uploaded" />
          </div>
        )}
      </div>
    );
  }
}

DragAndDropUploadImage.propTypes = {
  label: PropTypes.string,
  image: PropTypes.string,
  callback: PropTypes.func,
};

DragAndDropUploadImage.defaultProps = {
  label: "DragAndDropUploadImage",
  image: null,
  callback: () => {
    debugPrint("DragAndDropUploadImage pressed");
  },
};

export default DragAndDropUploadImage;
