import React from "react";
import "./GenericButton.scss";
import PropTypes from "prop-types";
import { debugPrint } from "../../utilities/Utilities";

class GenericButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        className="button-parent"
        onClick={this.props.callback}
        style={{
          background: this.props.color,
          fontSize: this.props.fontSize,
          color: this.props.fontColor,
          border: this.props.border,
          letterSpacing: this.props.spacing,
          margin: this.props.margin,
          padding: this.props.padding,
        }}>
        {this.props.label}
      </div>
    );
  }
}

GenericButton.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontColor: PropTypes.string,
  border: PropTypes.string,
  spacing: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  callback: PropTypes.func,
};

GenericButton.defaultProps = {
  label: "Test",
  color: "#1781BC",
  fontSize: "17px",
  fontColor: "white",
  margin: "1em",
  padding: "8px",
  callback: () => {
    debugPrint("GenericButton pressed");
  },
};

export default GenericButton;
