import React from "react";
import PropTypes from "prop-types";
import {
  debugPrint,
  makeID,
  updateURLParameter,
} from "../../utilities/Utilities";
import {
  adminCreateQuestions,
  adminDeletePaper,
  adminReadPaper,
  adminSetPaperQuestionsSortation,
  adminUpdatePaper,
  adminUpdatePapersPublishStatus,
  adminUpdateQuestion,
  clientGetPaperQuestionsSortation,
} from "../../utilities/Requests";
import {
  EventBus,
  HideDialog,
  HideLoading,
  ShowDialog,
  ShowLoading,
} from "../../utilities/EventBus";
import "./ModifyQuestionPage.scss";
import { PaperSettings, PaperStructure } from "../../configs/PaperConstants";
import APIResult from "../../configs/APIResult";
import EditPaperIcon from "../../assets/images/edit_paper_icon.svg";
import DeletePaperIcon from "../../assets/images/delete_paper_icon.svg";
import EditPaperLogo from "../../assets/images/edit_paper_logo.svg";
import CloseEditButton from "../../assets/images/close_edit_button.svg";
import GenericIconButton from "../widgets/GenericIconButton";
import TextTab from "../widgets/TextTab";
import FlatButton from "../widgets/FlatButton";
import {
  DialogType,
  GetDialogPack,
  ShowMessage,
} from "../../configs/DialogConstants";
import MasterQuestion from "../widgets/MasterQuestion";
import { createBrowserHistory } from "@remix-run/router";
import QuestionPreview from "../widgets/QuestionPreview";
import GenericButton from "../widgets/GenericButton";
import PublishStatus from "../widgets/PublishStatus";
import Sortation from "../widgets/Sortation";
const sha256 = require("sha256");

class ModifyQuestionPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newMasterQuestions: [],
      paperSettings: PaperSettings,
      sortation: [],
      newPaperSettings: {
        type: "",
        category: "",
        title: "",
        questions: [],
        publish: false,
        difficulty: 0,
      },
      mode: 0,
      showEditPaperDialog: false,
      showEditQuestionDialog: false,
      questionBeingEdit: null,
      allowDifficulty: false,
    };
    this.allNewMasterQuestions = {};
    this.eventBusInitialized = false;
    this.currentPaper = "";
    this.editQuestionReference = React.createRef();

    this.editPaperQuestionEvent = this.editPaperQuestionEvent.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
    this.resetQuestion = this.resetQuestion.bind(this);
    this.setAllMasterQuestions = this.setAllMasterQuestions.bind(this);
    this.getAllMasterQuestions = this.getAllMasterQuestions.bind(this);
    this.editPaper = this.editPaper.bind(this);
    this.deletePaper = this.deletePaper.bind(this);
    this.submitPaper = this.submitPaper.bind(this);
    this.checkMasterQuestion = this.checkMasterQuestion.bind(this);
    this.checkAndSubmitPaper = this.checkAndSubmitPaper.bind(this);
    this.getQuestions = this.getQuestions.bind(this);
    this.setNewPaperSettings = this.setNewPaperSettings.bind(this);
    this.setMode = this.setMode.bind(this);
  }

  setMode(m) {
    try {
      this.setState({ mode: Number(m) });
    } catch (e) {}
  }

  componentDidMount() {
    if (this.props.currentPaper !== "") {
      this.readPaper(this.props.currentPaper);
    }
    this.initiateEventBus();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.currentPaper !== prevState.currentPaper) {
      return nextProps.currentPaper;
    }
    return null;
  }

  initiateEventBus() {
    if (!this.eventBusInitialized) {
      EventBus.on("edit-paper-question", this.editPaperQuestionEvent);
      this.eventBusInitialized = true;
    }
  }

  editPaperQuestionEvent(data) {
    this.readPaper(data.page);
  }

  async deletePaper() {
    HideDialog();
    ShowLoading("刪除考卷中...請稍候...");
    let deletePaperResult = await adminDeletePaper(this.props.currentPaper);
    HideLoading();
    if (deletePaperResult.status !== APIResult.SUCCESS) {
      ShowMessage(
        DialogType.ERROR,
        "刪除考卷的流程中出現了一些不知名錯誤。\n\n" +
          JSON.stringify(deletePaperResult)
      );
    } else {
      createBrowserHistory().push(`/edit-paper-question/new`);
      window.location.reload();
    }
  }

  componentWillUnmount() {
    EventBus.remove("edit-paper-question", this.editPaperQuestionEvent);
  }

  async readPaper(id) {
    if (id === "new") {
      return;
    } else {
      document.title = "-";
    }
    ShowLoading("載入中...請稍候...");
    this.setState({ currentPaper: id });
    if (id) {
      let readPaperResult = await adminReadPaper(id);
      let sortationResult = await clientGetPaperQuestionsSortation(id);
      if (readPaperResult.status !== APIResult.SUCCESS) {
        return;
      }
      if (!readPaperResult.data._id) {
        HideLoading();
        ShowMessage(DialogType.ERROR, "無法讀取考卷內容", () => {
          window.location.href = "./new";
          // window.location.reload();
        });
        return;
      }
      this.currentPaper = this.props.currentPaper;
      let sortation = [];
      readPaperResult["data"].questions.forEach((eachQuestion, i) => {
        sortation.push(eachQuestion._id);
      });

      if (sortationResult.status === APIResult.SUCCESS) {
        try {
          sortation = sortationResult.data.sortation;
        } catch (e) {
          console.log(e);
        }
      }
      this.setState(
        {
          paperSettings: readPaperResult["data"],
          newPaperSettings: readPaperResult["data"],
          sortation,
        },
        () => {
          document.title = this.getTitle();
          this.checkAllowDifficulty();
        }
      );
    }
    HideLoading();
  }

  addQuestion() {
    var newMasterQuestions = this.state.newMasterQuestions;
    newMasterQuestions.push(`${Date.now()}-${makeID(8)}`);
    newMasterQuestions.reverse();
    this.setState({ newMasterQuestions: newMasterQuestions });
  }

  resetQuestion() {
    this.allNewMasterQuestions = {};
    this.setState({
      newMasterQuestions: Object.keys(this.allNewMasterQuestions),
    });
  }

  getQuestions() {
    const { paperSettings } = this.state;
    try {
      return paperSettings.questions;
    } catch (e) {
      return [];
    }
  }

  getTitle() {
    const { paperSettings } = this.state;
    let title = `${paperSettings["type"]}-${paperSettings["category"]} [${paperSettings["title"]}]`;
    return title;
  }

  checkMasterQuestion(allMasterQuestions) {
    var errors = [];
    try {
      var currentQuestion = 0;
      let allQuestions = [...allMasterQuestions];
      allQuestions.reverse().forEach((eachMasterQuestion, i) => {
        var header = ``;
        if (eachMasterQuestion.type === "題組") {
          header = `第${allQuestions.length - i}題 `;
          if (eachMasterQuestion.context.replace(" ", "") === "") {
            errors.push(`${header} - 題組文章待填`);
          }
        }

        eachMasterQuestion.all_questions.forEach((eachQuestion, j) => {
          currentQuestion = allQuestions.length - i;
          if (eachMasterQuestion.type === "題組") {
            currentQuestion = j + 1;
          }
          var fullHeader = `${header}第${currentQuestion}題`;
          if (eachMasterQuestion.type === "題組") {
            fullHeader = `${header}之問答${currentQuestion}`;
          }

          if (eachQuestion.header.replace(" ", "") === "") {
            errors.push(`${fullHeader} - 問題待填`);
          }
          for (const eachAnswerTag in eachQuestion.answers) {
            if (eachQuestion.answers[eachAnswerTag].replace(" ", "") === "") {
              errors.push(`${fullHeader} - ${eachAnswerTag}項的答案待填`);
            }
          }

          if (eachQuestion.correct_answer === 0) {
            errors.push(`${fullHeader} - 答案待選`);
          }

          if (eachQuestion.explanation.replace(" ", "") === "") {
            errors.push(`${fullHeader} - 詳解待填`);
          }
        });
      });
    } catch (e) {
      console.log(e);
    }
    return errors;
  }

  checkAndSubmitPaper() {
    var allMasterQuestions = this.getAllMasterQuestions();
    // allMasterQuestions.reverse();
    let checkResult = this.checkMasterQuestion(allMasterQuestions);
    if (checkResult.length > 0) {
      ShowDialog(
        GetDialogPack({
          showDialog: true,
          dialogType: DialogType.MISSING,
          dialogTitle: "哎呀，漏題了！",
          dialogMessage: `經檢測，發現了${checkResult.length}處漏填項目,是否繼續新增考題？`,
          dialogListInfo: checkResult,
          dialogShowPrimaryButton: true,
          dialogPrimaryButtonLabel: "返回",
          dialogPrimaryButtonCallback: HideDialog,
          dialogShowSecondaryButton: true,
          dialogSecondaryButtonLabel: "新增",
          dialogSecondaryAsRealMain: true,
          dialogSecondaryButtonCallback: () => {
            this.submitPaper(allMasterQuestions);
            HideDialog();
          },
          dialogShowCheckBox: true,
          dialogCheckBoxMessage: "是的，我要新增",
        })
      );
    } else {
      this.submitPaper(allMasterQuestions);
    }
  }

  async updatePaperPublishStatus(status) {
    const { paperSettings } = this.state;
    ShowDialog(
      GetDialogPack({
        showDialog: true,
        dialogType: DialogType.PUBLISH,
        dialogTitle: "發布試卷",
        dialogMessage: `是否選擇${status ? "" : "取消"}發布考卷？`,
        dialogShowPrimaryButton: true,
        dialogPrimaryButtonLabel: `${status ? "" : "取消"}發布`,
        dialogPrimaryButtonCallback: async () => {
          HideDialog();
          ShowLoading(`${status ? "" : "取消"}發布考卷中...請稍候...`);
          let result = await adminUpdatePapersPublishStatus(
            [paperSettings._id],
            status
          );
          HideLoading();
          if (result.status === APIResult.SUCCESS) {
            ShowMessage(
              DialogType.SUCCESS,
              `完成${status ? "" : "取消"}發布考卷。`,
              () => {
                HideDialog();
                if (this.props.currentPaper !== "") {
                  this.readPaper(this.props.currentPaper);
                }
              }
            );
          } else {
            ShowMessage(
              DialogType.FAILED,
              `${status ? "" : "取消"}發布考卷的流程中出現了錯誤。錯誤代碼 - ${
                result.status
              }`,
              () => {
                HideDialog();
                if (this.props.currentPaper !== "") {
                  this.readPaper(this.props.currentPaper);
                }
              }
            );
          }
        },
        dialogShowSecondaryButton: true,
        dialogSecondaryButtonLabel: "返回",
        dialogSecondaryButtonCallback: HideDialog,
        dialogShowCheckBox: true,
        dialogCheckBoxMessage: `是的，我要${status ? "" : "取消"}發布考卷`,
      })
    );
  }

  async submitPaper(allMasterQuestions) {
    ShowLoading("上傳考題中...請稍候...");
    let result = await adminCreateQuestions(allMasterQuestions);
    HideLoading();
    if (result.status === APIResult.SUCCESS) {
      ShowMessage(
        DialogType.SUCCESS,
        "考題已被上傳，點擊「題庫瀏覽」即可查閱已上傳考題。",
        () => {
          HideDialog();
          this.resetQuestion();
          this.readPaper(this.props.currentPaper);
          this.setState({ mode: 1 });
        }
      );
    } else {
      ShowMessage(
        DialogType.ERROR,
        "上傳考題的流程中出現了一些不知名錯誤。\n\n" + JSON.stringify(result)
      );
    }
  }

  async editQuestion(questionID) {
    ShowLoading("更新考題中...請稍候...");
    let masterQuestionObject =
      this.editQuestionReference.current.getMasterQuestionObject();
    let result = await adminUpdateQuestion(questionID, masterQuestionObject);

    HideLoading();
    if (result.status === APIResult.SUCCESS) {
      ShowMessage(
        DialogType.SUCCESS,
        "考題已被上傳，點擊「題庫瀏覽」即可查閱已上傳考題。",
        () => {
          HideDialog();
          window.location.reload();
        }
      );
    } else {
      ShowMessage(DialogType.FAILED, "更新考題流程發生了錯誤。請稍候再試。");
    }
  }

  checkAllowDifficulty() {
    const { newPaperSettings } = this.state;
    let isGeneric = newPaperSettings.category === "檢測考題";
    var _newPaperSettings = newPaperSettings;
    newPaperSettings.difficulty = isGeneric ? 0 : newPaperSettings.difficulty;
    this.setState({
      newPaperSettings: _newPaperSettings,
      allowDifficulty: !isGeneric,
    });
  }

  removeQuestion(eachNewQuestion) {
    delete this.allNewMasterQuestions[eachNewQuestion];
    this.setState(
      {
        newMasterQuestions: Object.keys(this.allNewMasterQuestions),
      },
      () => {
        HideDialog();
      }
    );
  }

  setAllMasterQuestions(allMasterQuestions) {}

  getAllMasterQuestions() {
    var allMasterQuestionObject = [];
    Object.keys(this.allNewMasterQuestions).forEach(eachMasterQuestion => {
      if (this.allNewMasterQuestions[eachMasterQuestion]) {
        allMasterQuestionObject.push(
          this.allNewMasterQuestions[
            eachMasterQuestion
          ].getMasterQuestionObject()
        );
      }
    });

    return allMasterQuestionObject;
  }

  setNewPaperSettings(key, value) {
    const { newPaperSettings } = this.state;
    var currentNewPaperSettings = newPaperSettings;
    currentNewPaperSettings[key] = value;
    this.setState(
      { newPaperSettings: currentNewPaperSettings },
      this.checkAllowDifficulty
    );
  }

  async editPaper() {
    const { paperSettings, newPaperSettings } = this.state;
    ShowLoading("修改考卷中...請稍候...");
    let result = await adminUpdatePaper(paperSettings._id, {
      type: newPaperSettings.type,
      category: newPaperSettings.category,
      title: newPaperSettings.title,
      publish: newPaperSettings.publish,
      difficulty: newPaperSettings.difficulty,
    });

    if (result.status === APIResult.SUCCESS) {
      HideLoading();
      window.location.reload();
    } else {
      HideLoading();
      ShowMessage(DialogType.FAILED, "刪除考卷流程發生了錯誤。請稍候再試。");
      this.setState({ showEditPaperDialog: false });
    }
  }

  triggerEditCallback(question) {
    this.setState({
      showEditQuestionDialog: true,
      questionBeingEdit: question,
    });
  }

  updateSortation(sortation) {
    const { paperSettings } = this.state;
    // ShowLoading("更新排序中...請稍候...");
    let result = adminSetPaperQuestionsSortation(paperSettings._id, {
      sortation: sortation,
    });
    // HideLoading();
    // if (result.status === APIResult.SUCCESS) {
    //   ShowMessage(
    //     DialogType.SUCCESS,
    //     "考題排序已被更新，點擊「題庫瀏覽」即可查閱已上傳考題。",
    //     () => {
    //       HideDialog();
    //       this.readPaper(this.props.currentPaper);
    //       this.setState({ mode: 1 });
    //     }
    //   );
    // } else {
    //   ShowMessage(DialogType.FAILED, "更新考題排序流程發生了錯誤。請稍候再試。");
    // }
  }

  render() {
    const {
      paperSettings,
      mode,
      questionBeingEdit,
      showEditQuestionDialog,
      newPaperSettings,
      showEditPaperDialog,
      sortation,
    } = this.state;
    let questionCount = 1;
    return (
      <div className="">
        <div className="modify-question-parent gap-v-m " style={{}}>
          <div className="modify-question-title-bar">
            {/* <div style={{ width: 100 }}></div> */}
            <div className="modify-question-title-group">
              <h1 className="modify-question-title">{this.getTitle()}</h1>
              <GenericIconButton
                icon={EditPaperIcon}
                size={16}
                callback={() => {
                  this.setState({ showEditPaperDialog: true });
                }}
              />
              <GenericIconButton
                icon={DeletePaperIcon}
                size={16}
                callback={() => {
                  ShowDialog(
                    GetDialogPack({
                      showDialog: true,
                      dialogType: DialogType.DELETE,
                      dialogTitle: "刪除",
                      dialogMessage: "是否確定刪除此考卷？",
                      dialogShowPrimaryButton: true,
                      dialogPrimaryButtonLabel: "確認",
                      dialogPrimaryButtonCallback: this.deletePaper,
                      dialogShowSecondaryButton: true,
                      dialogSecondaryButtonLabel: "返回",
                      dialogSecondaryButtonCallback: HideDialog,
                      dialogShowCheckBox: true,
                      dialogCheckBoxMessage: "是的，我要刪除",
                    })
                  );
                }}
              />
            </div>
            <div className="modify-question-publish-group">
              <GenericButton
                padding="4px 16px"
                label="預覽"
                callback={() => {
                  let data = JSON.parse(
                    localStorage.getItem("jyreadingQuizAC")
                  );
                  let token = data.sessionToken;
                  if (!token) {
                    return;
                  }
                  let hashedToken = sha256(token);

                  window.open(
                    `https://quiz.jyreading.com/quiz/${paperSettings._id}/${hashedToken}`
                  );
                }}
              />
              <div className="modify-question-publish-text">發布</div>
              <input
                role="switch"
                type="checkbox"
                onChange={e => this.updatePaperPublishStatus(e.target.checked)}
                checked={paperSettings["publish"]}
              />
            </div>
          </div>
          {paperSettings.difficulty > 0 && (
            <div className="center modify-question-difficulty">{`難度 ${"★".repeat(
              paperSettings.difficulty
            )}${"☆".repeat(5 - paperSettings.difficulty)}`}</div>
          )}
          <TextTab
            currentTab={mode}
            labels={["新增考題", "題庫瀏覽", "發布狀態", "排序"]}
            callback={mode => {
              this.setState({ mode: mode });
              window.history.pushState(
                {},
                "",
                updateURLParameter(window.location.href, "m", mode)
              );
            }}
          />
          <div
            id="new-question"
            className={`gap-v-m ${mode !== 0 ? "hide" : ""}`}>
            <FlatButton label="＋新增考題" callback={this.addQuestion} />
            {this.state.newMasterQuestions.map((eachNewQuestion, i) => {
              return (
                <div key={eachNewQuestion} className="question-block gap-v-m">
                  <MasterQuestion
                    paperSettings={paperSettings}
                    isMock={paperSettings.type === "模擬考"}
                    index={this.state.newMasterQuestions.length - i - 1}
                    deleteQuestion={() => this.removeQuestion(eachNewQuestion)}
                    ref={r => {
                      this.allNewMasterQuestions[eachNewQuestion] = r;
                    }}
                    _id={eachNewQuestion}
                  />
                </div>
              );
            })}
            <div className="center">
              <FlatButton
                label="確認上傳"
                compact={true}
                active={true}
                curve={true}
                fit={true}
                callback={this.checkAndSubmitPaper}
              />
            </div>
          </div>
          <div
            id="questions-list"
            className={`gap-v-m ${mode !== 1 ? "hide" : ""}`}>
            {this.getQuestions().map((eachQuestion, i) => {
              questionCount += eachQuestion.all_questions.length;
              return (
                <QuestionPreview
                  triggerEditCallback={() => {
                    this.triggerEditCallback(eachQuestion);
                  }}
                  index={questionCount - eachQuestion.all_questions.length}
                  key={eachQuestion._id + i}
                  question={eachQuestion}
                  isFull={
                    paperSettings.type === "題組" ||
                    eachQuestion.mock_type === "題組"
                  }
                />
              );
            })}
          </div>
          <div
            id="publish-status"
            className={`gap-v-m ${mode !== 2 ? "hide" : ""}`}>
            <PublishStatus paperSettings={paperSettings} />
          </div>
          <div id="sortation" className={`gap-v-m ${mode !== 3 ? "hide" : ""}`}>
            <Sortation
              paperSettings={paperSettings}
              sortation={sortation}
              updateSortation={s => {
                this.setState({ sortation: s });
                this.updateSortation(s);
              }}
            />
          </div>
          <div style={{ paddingTop: 16 }}></div>
        </div>
        {showEditPaperDialog && (
          <div className="modify-question-edit-paper-details-container">
            <div className="modify-question-edit-paper-details-dialog-container">
              <div className="modify-question-edit-paper-details-dialog-title-bar">
                <h3>編輯考卷模板</h3>
                <img src={EditPaperLogo} alt="edit paper" />
              </div>
              <div className="modify-question-edit-paper-details-dialog-content-container center">
                <div className="dropdown-parent">
                  <div>
                    <label htmlFor="selector">題型</label>
                    <select
                      onChange={e => {
                        this.setNewPaperSettings("type", e.target.value);
                      }}
                      value={newPaperSettings.type}
                      id="questions"
                      title="questions">
                      {Object.keys(PaperStructure).map(option => {
                        return (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="selector">測驗</label>
                    <select
                      onChange={e => {
                        this.setNewPaperSettings("category", e.target.value);
                      }}
                      value={newPaperSettings.category}
                      id="questions"
                      title="questions">
                      {(PaperStructure[newPaperSettings.type]
                        ? PaperStructure[newPaperSettings.type]
                        : []
                      ).map(option => {
                        return (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    style={{
                      pointerEvents: this.state.allowDifficulty
                        ? "auto"
                        : "none",
                      opacity: this.state.allowDifficulty ? 1 : 0.75,
                      filter: `saturate(${this.state.allowDifficulty ? 1 : 0})`,
                    }}>
                    <label htmlFor="selector">難度</label>
                    <select
                      onChange={e => {
                        this.setNewPaperSettings("difficulty", e.target.value);
                      }}
                      value={newPaperSettings.difficulty}
                      id="questions"
                      title="questions">
                      {[0, 1, 2, 3, 4, 5].map(option => {
                        return (
                          <option key={option} value={option}>
                            {"★".repeat(option)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="selector">名稱</label>
                    <input
                      value={newPaperSettings.title}
                      className="paper-title-input"
                      onChange={e => {
                        this.setNewPaperSettings("title", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="modify-question-edit-paper-details-buttons-group">
                  <GenericButton
                    label="確認"
                    color="#1969BF"
                    border="2px solid #57ACDC4"
                    margin="0em"
                    padding="0.5em 1.5em"
                    callback={() => {
                      this.editPaper();
                    }}
                  />
                  <GenericButton
                    label="返回"
                    color="#DEEEF8"
                    fontColor="#4A7AA7"
                    border="2px solid #8CA2C4"
                    margin="0em"
                    padding="0.5em 1.5em"
                    callback={() => {
                      this.setState({ showEditPaperDialog: false });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {showEditQuestionDialog && questionBeingEdit && (
          <div className="fixed-full-screen dark-backdrop center">
            <div className="modify-question-edit-question-details-container">
              <img
                alt="close edit"
                src={CloseEditButton}
                className="self-end button p-s"
                onClick={() => {
                  this.setState({
                    showEditQuestionDialog: false,
                    questionBeingEdit: null,
                  });
                }}
              />
              <div className="height-100 scroll">
                <MasterQuestion
                  ref={this.editQuestionReference}
                  isMock={paperSettings.type === "模擬考"}
                  paperSettings={paperSettings}
                  index={0}
                  editMode={true}
                  masterQuestion={questionBeingEdit}
                />
              </div>
              <GenericButton
                label="儲存更新"
                callback={() => this.editQuestion(questionBeingEdit._id)}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

ModifyQuestionPage.propTypes = {
  label: PropTypes.string,
  callback: PropTypes.func,
};

ModifyQuestionPage.defaultProps = {
  label: "Button",
  callback: () => {
    debugPrint("ModifyQuestionPage pressed");
  },
};

export default ModifyQuestionPage;
