import React from "react";
import "./StatisticsPage.scss";
import ProfilePicture from "../../assets/images/profile_picture.png";
import withRouter from "../../utilities/withRouter";
import { HideLoading, ShowLoading } from "../../utilities/EventBus";
import { createBrowserHistory } from "@remix-run/router";
import {
  adminGetStatisticsData,
  adminReadPaper,
} from "../../utilities/Requests";

import { PaperStructure } from "../../configs/PaperConstants";
import {
  commafy,
  epochToDateTime,
  getCleanNumber,
} from "../../utilities/Utilities";
import { LOW_SCORE_THRESHOLD } from "../../configs/StatisticsConstant";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloseEditButton from "../../assets/images/close_edit_button.svg";
import dayjs from "dayjs";
import APIResult from "../../configs/APIResult";
import QuestionPreview from "../widgets/QuestionPreview";
import { InputAdornment, TextField } from "@mui/material";

class StatisticsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentType: "閱讀單題",
      oriAnswers: [],
      answers: [],
      papers: [],
      paperData: {},
      selectedPaper: "",
      currentExpandedType: {
        檢測考題: true,
      },
      from: Date.now() - 1000 * 60 * 60 * 24 * 30 * 3,
      to: Date.now(),
      lowestAnswerAcceptance: 10,
    };

    this.mainTabs = [
      {
        label: "閱讀單題",
        type: "閱讀單題",
        className: "green",
      },
      {
        label: "語文題型",
        type: "語文",
        className: "blue",
      },
      {
        label: "題組題型",
        type: "題組",
        className: "purple",
      },
      {
        label: "模擬考題",
        type: "模擬考",
        className: "yellow",
      },
    ];
    this.eventBusInitialized = false;
  }

  async componentDidMount() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params["type"]) {
      await this.setState({
        currentType: params["type"],
      });
    }
    if (params["paper"]) {
      await this.setState({
        selectedPaper: params["paper"],
      });
    }
    this.initiateEventBus();
    this.getData();
  }

  getColorByType() {
    // loop through mainTabs
    for (var i = 0; i < this.mainTabs.length; i++) {
      if (this.mainTabs[i].type === this.state.currentType) {
        return this.mainTabs[i].className;
      }
    }
    return "";
  }

  async getData() {
    ShowLoading("讀取數據中...請稍候...");
    let answers = await adminGetStatisticsData({
      get_answers: true,
      paper_filter: { type: this.state.currentType },
    });
    if (answers.status === APIResult.SUCCESS) {
      this.setState({
        answers: answers.answers,
        oriAnswers: answers.answers,
      });
    }

    let papers = await adminGetStatisticsData({
      get_papers: true,
      paper_filter: { type: this.state.currentType },
    });
    if (papers.status === APIResult.SUCCESS) {
      this.setState({
        papers: papers.papers,
      });
    }

    if (this.state.selectedPaper !== "") {
      let result = await adminReadPaper(this.state.selectedPaper);
      if (result.status === APIResult.SUCCESS) {
        this.setState({
          paperData: result.data,
        });
      }
    }
    this.updateLowestAnswerAcceptance();
    HideLoading();
  }

  currentMonthStartEpoch() {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1).getTime();
  }

  getPaperBasicData(paperID) {
    const { answers } = this.state;
    let answerData = { correct: 0, wrong: 0 };
    let totalAnswered = 0;
    let totalAnsweredThisMonth = 0;
    let totalWithinRange = 0;

    answers.forEach(eachAnswerSet => {
      if (eachAnswerSet.paper_id === paperID) {
        totalAnswered++;

        if (eachAnswerSet.submit_time > this.currentMonthStartEpoch()) {
          totalAnsweredThisMonth++;
        }

        if (
          eachAnswerSet.submit_time > this.state.from &&
          eachAnswerSet.submit_time < this.state.to
        ) {
          totalWithinRange++;
          eachAnswerSet.answers.forEach(eachAnswer => {
            if (eachAnswer.correct === eachAnswer.answer) {
              answerData.correct++;
            } else {
              answerData.wrong++;
            }
          });
        }
      }
    });

    let accuracy = (
      answerData.correct /
      (answerData.correct + answerData.wrong)
    ).toFixed(2);
    return {
      accuracy: accuracy === "NaN" ? "-" : accuracy,
      totalAnswered: totalAnswered,
      totalWithinRange: totalWithinRange,
      totalAnsweredThisMonth: totalAnsweredThisMonth,
    };
  }

  getPaperDetailsData() {
    let data = {};
    let answerList = [];
    let questionList = [];
    this.state.answers.forEach(eachAnswerSet => {
      if (eachAnswerSet.paper_id === this.state.selectedPaper) {
        answerList.push(eachAnswerSet.answers);
      }
    });
    //
    if (this.state.paperData.questions) {
      this.state.paperData.questions.forEach((eachMasterQuestion, i) => {
        eachMasterQuestion.all_questions.forEach((eachQuestion, i) => {
          questionList.push(eachQuestion);
        });
      });
    }
    questionList.forEach((eachQuestion, i) => {
      let questionData = {
        question: eachQuestion,
        correct: 0,
        wrong: 0,
        total: 0,
        pickedA: 0,
        pickedB: 0,
        pickedC: 0,
        pickedD: 0,
        index: i,
      };
      answerList.forEach(eachAnswer => {
        let answer = eachAnswer.find(
          eahcSubAnswer => eahcSubAnswer.id === eachQuestion._id
        );
        if (answer) {
          questionData.total++;
          if (answer.correct === answer.answer) {
            questionData.correct++;
          } else {
            questionData.wrong++;
          }
          switch (answer.answer) {
            case 0:
              questionData.pickedA++;
              break;
            case 1:
              questionData.pickedB++;
              break;
            case 2:
              questionData.pickedC++;
              break;
            case 3:
              questionData.pickedD++;
              break;
            default:
              break;

            // no default
          }
        }
      });
      questionData.accuracy = (
        questionData.correct /
        (questionData.correct + questionData.wrong)
      ).toFixed(2);
      data[eachQuestion._id] = questionData;
    });
    return data;
  }

  initiateEventBus() {
    // if (!this.eventBusInitialized) {
    //   EventBus.on("edit-paper-question", data => {
    //   });
    //   this.eventBusInitialized = true;
    // }
  }

  componentWillUnmount() {
    // EventBus.remove("edit-paper-question");
  }

  changePaper(paperID) {
    // add paper to url, or update url if paper already exists
    let url = new URL(window.location.href);
    url.searchParams.set("paper", paperID);
    createBrowserHistory().push(url.pathname + url.search);

    this.setState(
      {
        selectedPaper: paperID,
      },
      this.getData
    );
  }

  changeType(type) {
    // add type to url, or update url if type already exists
    let url = new URL(window.location.href);
    url.searchParams.set("type", type);
    // remove paper from url
    url.searchParams.delete("paper");
    createBrowserHistory().push(url.pathname + url.search);

    this.setState(
      {
        currentType: type,
      },
      this.getData
    );
  }

  getTabButtons(tabInfo, onClick, first) {
    return (
      <>
        {!first && (
          <div key={`div-${tabInfo.label}`} className="tab-divider"></div>
        )}
        <div
          key={`btn-${tabInfo.label}`}
          onClick={onClick}
          className={`statistics-tab ${tabInfo.className} ${
            this.state.currentType === tabInfo.type ? "on" : ""
          }`}>
          {tabInfo.label}
        </div>
      </>
    );
  }

  getTypesListing() {
    if (this.state.currentType === "模擬考") {
      return ["檢測考題"];
    }
    return PaperStructure[this.state.currentType] ?? [];
  }

  getProperPaper(eachCategory) {
    let properPaper = [];
    this.state.papers.forEach(eachPaper => {
      if (eachPaper.category === eachCategory) {
        properPaper.push(eachPaper);
      }
    });
    return properPaper;
  }

  dayJSToEpoch(dayjs) {
    return dayjs.valueOf();
  }

  updateLowestAnswerAcceptance(value) {
    let answerRate = Number(
      getCleanNumber(value ?? this.state.lowestAnswerAcceptance)
    );
    this.setState({
      lowestAnswerAcceptance: answerRate,
    });

    let acceptedAnswers = [];

    this.state.oriAnswers.forEach(eachAnswerSet => {
      let answered = 0;
      eachAnswerSet.answers.forEach(eachAnswer => {
        if (eachAnswer.answer !== -1) {
          answered++;
        }
      });

      if (answered / eachAnswerSet.answers.length > answerRate / 100) {
        acceptedAnswers.push(eachAnswerSet);
      }
    });

    this.setState({
      answers: acceptedAnswers,
    });
  }

  splitPaperDetails(paperDetails) {
    let chunkedData = [];
    let chunk = [];
    Object.keys(paperDetails).forEach(eachQuestionID => {
      chunk.push(paperDetails[eachQuestionID]);
      if (chunk.length === 10) {
        chunkedData.push(chunk);
        chunk = [];
      }
    });

    if (chunk.length > 0) {
      chunkedData.push(chunk);
    }

    return chunkedData;
  }

  render() {
    const paperDetails = this.getPaperDetailsData();
    const chunkedPaperDetails = this.splitPaperDetails(paperDetails);
    const currentPaperData = this.state.paperData;
    const color = this.getColorByType();
    let questionCount = 1;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
        }}
        className="h">
        <div className="top-bar">
          <a className="main-title a-clear" href="https://www.jyreading.com">
            財團法人江雲教育基金會
          </a>

          <div className="top-bar-right">
            <div
              className="nav-title"
              onClick={() => {
                createBrowserHistory().push(`/statistics`);
                window.location.reload();
              }}>
              查看數據
            </div>

            <div
              className="nav-title"
              onClick={() => {
                createBrowserHistory().push(`/edit-paper-question/new`);
                window.location.reload();
              }}>
              編輯考題
            </div>

            <img
              alt="profile"
              src={ProfilePicture}
              className="profile-picture"
            />
          </div>
        </div>
        <div className="statistic-content-wrapper">
          <div className="statistic-content">
            <div className="statistic-tabs-wrapper">
              {this.mainTabs.map((tab, index) => {
                return this.getTabButtons(
                  tab,
                  () => this.changeType(tab.type),
                  index === 0
                );
              })}
            </div>
            <div>
              {/* Add from and to date selector */}
              <div className="date-selector">
                <div className="date-selector-label">從</div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="YYYY/MM/DD"
                    onChange={newValue => {
                      this.setState({ from: newValue.valueOf() });
                    }}
                    value={dayjs(this.state.from)}
                    maxDate={dayjs(epochToDateTime(Date.now()))}
                  />
                </LocalizationProvider>
                <div className="date-selector-label">到</div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="YYYY/MM/DD"
                    onChange={newValue => {
                      this.setState({ to: newValue.valueOf() });
                    }}
                    value={dayjs(epochToDateTime(this.state.to))}
                    maxDate={dayjs(epochToDateTime(Date.now()))}
                  />
                </LocalizationProvider>
                <div className="date-selector-label">最低回答率</div>
                <TextField
                  value={this.state.lowestAnswerAcceptance.toString()}
                  onChange={e => {
                    this.updateLowestAnswerAcceptance(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  className="lowest-accuracy-input"
                />
                {/* Filter button */}
              </div>
            </div>
            {this.getTypesListing().map(eachCategory => {
              return (
                <div key={eachCategory} className="statistics-table-wrapper">
                  <div
                    className={`sidebar-buttons unselectable ${color}`}
                    onClick={() => {
                      var currentExpandedType = this.state.currentExpandedType;
                      currentExpandedType[eachCategory] =
                        !currentExpandedType[eachCategory];
                      this.setState({
                        currentExpandedType: currentExpandedType,
                      });
                    }}>
                    {!this.state.currentExpandedType[eachCategory] ? (
                      <svg
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.316024 6.70586C0.735578 7.12148 1.41332 7.12148 1.83288 6.70586L6.00691 2.5709L10.1809 6.70586C10.6005 7.12149 11.2782 7.12149 11.6978 6.70586C12.1173 6.29023 12.1173 5.61883 11.6978 5.20321L6.75995 0.311598C6.3404 -0.104029 5.66266 -0.104029 5.2431 0.311598L0.305266 5.20321C-0.103531 5.60818 -0.103531 6.29023 0.316024 6.70586Z"
                          fill="#005A03"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.6964 0.31172C11.2769 -0.103907 10.5991 -0.103907 10.1796 0.31172L6.00555 4.44667L1.83152 0.311721C1.41196 -0.103906 0.734219 -0.103906 0.314664 0.311721C-0.104891 0.727348 -0.104891 1.39874 0.314664 1.81437L5.2525 6.70598C5.67205 7.12161 6.3498 7.12161 6.76935 6.70598L11.7072 1.81437C12.116 1.4094 12.116 0.727347 11.6964 0.31172Z"
                          fill="#005A03"
                        />
                      </svg>
                    )}
                    {eachCategory}
                  </div>
                  {this.state.currentExpandedType[eachCategory] ? (
                    <table className="statistic-table">
                      <thead className={`${color}`}>
                        <tr>
                          <th>卷名</th>
                          <th>正答率</th>
                          <th>作答人數</th>
                          <th>本月作答人數</th>
                          <th>累積作答人數</th>
                          <th>上線時間</th>
                          <th>逐題數據</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.getProperPaper(eachCategory).map(
                          (eachPaper, i2) => {
                            let paperData = this.getPaperBasicData(
                              eachPaper._id
                            );
                            return (
                              <tr
                                key={eachPaper._id}
                                className={`${color} ${
                                  i2 % 2 === 1 ? "dark" : ""
                                }`}>
                                <td className={`title ${color}`}>
                                  {eachPaper.title}
                                </td>
                                <td
                                  className={`${
                                    paperData.accuracy < LOW_SCORE_THRESHOLD
                                      ? "red"
                                      : ""
                                  }`}>
                                  {paperData.accuracy}
                                </td>
                                <td>{commafy(paperData.totalWithinRange)}</td>
                                <td>
                                  {commafy(paperData.totalAnsweredThisMonth)}
                                </td>
                                <td>{commafy(paperData.totalAnswered)}</td>
                                <td>
                                  {epochToDateTime(eachPaper.update_time)}
                                </td>
                                <td>
                                  <div
                                    className={`statistics-more-content ${color}`}
                                    onClick={() => {
                                      this.changePaper(eachPaper._id);
                                    }}>
                                    前往{" "}
                                    <svg
                                      width="8"
                                      height="11"
                                      viewBox="0 0 8 11"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M1 1L6.72727 5.5L1 10"
                                        stroke="#0C7510"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={`statistic-paper-wrapper ${
            this.state.selectedPaper === "" ? "" : "show"
          }`}>
          <div className="statistic-paper">
            {/* Close button */}
            <div className="statistic-top-bar">
              <img
                className="close-button"
                onClick={() => {
                  // remove paper from url
                  let url = new URL(window.location.href);
                  url.searchParams.delete("paper");
                  createBrowserHistory().push(url.pathname + url.search);

                  this.setState({
                    selectedPaper: "",
                  });
                }}
                src={CloseEditButton}
                alt="close"
              />
            </div>
            <div className="statistic-paper-content">
              <div className={`statistic-paper-title ${color}`}>
                {currentPaperData.type} - {currentPaperData.title}
              </div>

              {chunkedPaperDetails.map((eachChunk, i) => {
                return (
                  <table
                    className="statistic-paper-details-table"
                    key={`${i}-table`}>
                    <thead className={`${color}`}>
                      <tr className="">
                        <th className={`title ${color}`}>題數</th>
                        {eachChunk.map((eachQuestion, i2) => {
                          return (
                            <th
                              className={`index ${color}`}
                              key={`question-${eachQuestion.index + 1}`}>
                              {eachQuestion.index + 1}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className={`title ${color}`}>正答率</th>
                        {eachChunk.map((eachQuestion, i2) => {
                          return (
                            <th
                              className={`${color} ${
                                getCleanNumber(eachQuestion.accuracy) < 0.5
                                  ? "red"
                                  : ""
                              }`}
                              key={`accuracy-${eachQuestion.index + 1}`}>
                              {getCleanNumber(eachQuestion.accuracy)}
                            </th>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                );
              })}

              <div className="statistic-paper-details-questions">
                {currentPaperData.questions?.map((eachMasterQuestion, i) => {
                  let accuracyData = {};
                  eachMasterQuestion.all_questions.forEach(eachQuestion => {
                    accuracyData[eachQuestion._id] =
                      paperDetails[eachQuestion._id];
                  });
                  questionCount += eachMasterQuestion.all_questions.length;
                  return (
                    // <MasterQuestion
                    //   masterQuestion={eachMasterQuestion}
                    //   paperSettings={currentPaperData}
                    // />
                    <QuestionPreview
                      triggerEditCallback={() => {
                        // this.triggerEditCallback(eachQuestion);
                      }}
                      index={
                        questionCount - eachMasterQuestion.all_questions.length
                      }
                      key={eachMasterQuestion._id + i}
                      question={eachMasterQuestion}
                      isFull={false}
                      accuracyData={accuracyData}
                      color={color}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(StatisticsPage);
